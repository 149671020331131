import { Component, DestroyRef, Inject, computed } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { QuestionGroup } from '../../questions.model';
import { QuestionGroupService } from '../questions.service';
import { Subject } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterLinkWithHref } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService, Branch } from 'src/app/app.service';

@Component({
  selector: 'app-question-group-add',
  templateUrl: './question-group-add.component.html',
  styleUrls: ['./question-group-add.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatSelectModule,
  ],
})
export class QuestionGroupAddComponent {
  constructor(
    private questionService: QuestionGroupService,
    private toastrService: ToastrService,
    private dialogRef: MatDialogRef<QuestionGroupAddComponent>,
    private destroyRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) public _data: { categoryId: number }
  ) {}
  questionGListChanges = new Subject<void>();
  questionForm = new FormGroup({
    categoryId: new FormControl(this._data.categoryId, Validators.required),
    title: new FormControl(''),
    details: new FormControl(''),
    numOfShortQuestions: new FormControl(-1),
    numOfLongQuestions: new FormControl(-1),
  });

  submit() {
    const question = this.questionForm.value;
    const questionG: QuestionGroup = {
      title: question.title ?? '',
      categoryId: question.categoryId ?? -1,
      details: question.details ?? '',
      numOfLongQuestions: question.numOfLongQuestions ?? 0,
      numOfShortQuestions: question.numOfShortQuestions ?? 0,
    };
    this.questionService
      .create(questionG)

      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.toastrService.success('Added successfully', '', { timeOut: 3000 });
        this.dialogRef.close(true);
      });
  }
}
