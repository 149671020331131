export const environment = {
  stage: 'dev',
  serverUrl: 'https://dev.engineeringdashboard.yashilabs.com/',
  production: false,
  apiUrl: 'https://dev.api.engineering.yashilabs.com/',
  authUrl: 'https://accounts.yashilabs.com/',
  realm: 'engineering',
  client: 'engineering_quiz',
  s3Config: {
    region: 'ap-south-1',
    credentials: {
      accessKeyId: 'AKIA2ADY6YFMLKSGC3GG',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    },
  },
  s3Bucket: 'yashi-labs',
  s3Object: 'engineering_quiz_prod/files/',
  s3Url: 'https://yashi-labs.s3.ap-south-1.amazonaws.com/',
};
