import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { QuestionGroup } from '../questions.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionGroupService extends AbstractService<QuestionGroup> {
  serviceName(): string {
    return 'question-groups';
  }
}
