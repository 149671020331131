import { JsonPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { QuestionSet } from '../../questions.model';
import { Subject, filter, map, switchMap, takeUntil, tap } from 'rxjs';
import { QuestionsSetService } from '../question-set.service';
import { QuestionGroupService } from '../../Questions-Group/questions.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-question-set-add',
  templateUrl: './question-set-add.component.html',
  styleUrls: ['./question-set-add.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
  ],
})
export class QuestionSetAddComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { id: number },
    private dialogRef: MatDialogRef<QuestionSetAddComponent>,
    private questionSetService: QuestionsSetService,
    private toast: ToastrService
  ) {
    this.questionSetForm.get('unitId')?.setValue(Number(_data.id));
  }
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  questionSetForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    runningTime: new FormControl(),
    requiredCoins: new FormControl(),
    unitId: new FormControl(),
  });

  ngOnInit(): void {}
  submit() {
    const question = this.questionSetForm.value;
    const questionG: QuestionSet = {
      title: question.title ?? '',
      runningTime: question.runningTime ?? '',
      requiredCoins: question.requiredCoins ?? '',
      unitId: this._data.id ?? 0,
    };
    this.questionSetService.create(questionG).subscribe((res: any) => {
      this.toast.success('Question set Added successfully');
      this.dialogRef.close();
    });
  }
}
