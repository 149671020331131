import { CommonModule, JsonPipe } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterLinkWithHref } from '@angular/router';
import { CATEGORY, Level, Options, Question } from '../../questions.model';
import {
  Subject,
  filter,
} from 'rxjs';
import { QuestionService } from '../questions.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { FileUploadComponent } from 'src/app/file-upload/file-upload.component';
import { UploadFileComponent } from 'src/app/upload-file/upload-file.component';
import { S3Service } from 'src/app/s3.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { OptionsAddComponent } from 'src/app/options/option-add/option-add.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UnitService } from 'src/app/unit.service';

export const languages = [
  'java',
  'c',
  'cpp',
  'html',
  'javascript',
  'typescript',
  'php',
  'css',
] as const;
export type LANGUAGE = (typeof languages)[number];
@Component({
  selector: 'app-question-set-add',
  templateUrl: './questions-add.component.html',
  styleUrls: ['./questions-add.component.scss'],

  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    UploadFileComponent,
    FileUploadComponent,
  ],
})
export class QuestionAddComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { id: number },
    private questions: QuestionService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<QuestionAddComponent>,
    private s3Service: S3Service,
    private toastService: ToastrService,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
  ) {
    this.questionForm = this.fb.group({
      title: new FormControl('', Validators.required),
      translation: new FormControl(),
      category: new FormControl('TEXT'),
      level: new FormControl('EASY'),
      correctOption: new FormControl<string | null>(null, Validators.required),
      options: new FormControl<Options[]>([]),
      correctOptionReason: new FormControl(''),
      unit: new  FormControl(''),
      passage : new FormControl(''),
      questionSetId: new FormControl<number[]>([]),
      language: new FormControl<string | null>('java'),
    });
  }
  questionForm: FormGroup;
  languages = languages;
  Levels = Object.keys(Level);
  Category = Object.keys(CATEGORY);
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  readonly codeForm = this.fb.group({
    language: new FormControl<LANGUAGE>('java', Validators.required),
    snippet: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(5),
    ]),
  });
  optionCtrl = new FormControl('');
  correctOptionCtrl = new FormControl<String | null>(null);
  idCtrl = new FormControl('');
  selectedFile: File | null = null;
  questionGListChanges$ = new Subject<void>();
  ngOnInit(): void {
    // this.units = this.questionForm.get('unit')?.valueChanges.pipe(
    //   filter((v) => !!v),
    //   takeUntilDestroyed(this.destroyRef),
    //   debounceTime(700),
    //   distinctUntilChanged(),
    //   switchMap((v) => {
    //     const filter: FilterDTO = {
    //       pageNumber: 1,
    //       pageSize: 5,
    //       search: `title:${v}*`,
    //     };
    //     return this.unitService
    //       .filterData(filter)
    //       .pipe(map((page) => page.elements));
    //   })
    // );

    this.category?.valueChanges
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((cat) => {
        if (cat === 'CODE') {
          this.questionForm.addControl('code', this.codeForm);
        } else {
          this.questionForm.removeControl('code');
        }
      });
  }
  get options() {
    return this.questionForm.get('options');
  }

  get cOptions() {
    return this.questionForm.get('correctOption');
  }
  remove(i: number): void {
    const optionFc = this.options;

    const arr = (optionFc?.value as Options[]) ?? [];
    const v = arr[i];
    if (v.name === this.cOptions?.value) this.cOptions.reset();
    arr.splice(i, 1);
    optionFc?.setValue(arr);
  }

  onOptionEntered(optionInput: HTMLInputElement) {
    const value = optionInput.value;
    if (value.length == 0) return;
    optionInput.value = '';

    const arrr = this.options?.value as Options[];

    if (arrr.length === 4) return;
    const i = arrr.findIndex((t) => t.name == value);
    if (i != -1) return;
    arrr.push({
      name: value,
      correct: false,
    });
  }

  onCorrectOptionSelected(option: string) {
    const arrr = this.options?.value as Options[];
    arrr.forEach((o) => (o.correct = o.name === option));
  }
  submit() {
    const question = this.questionForm.value;
    let questionG: Question = {
      title: question.title ?? '',
      translation: question.translation,
      category: question.category ?? '',
      level: question.level ?? '',
      options: question.options ?? [],
      correctOptionReason: question.correctOptionReason ?? '',
      passage: question.passage,
      code: this.questionForm.get('code')?.value ?? null,
      unit:{
        id: question.unit ?? 0,
      }
    };
    if (this.selectedFile)
      questionG = {
        ...questionG,
        file: {
          key: this.selectedFile.name,
          alt: this.selectedFile.name,
        },
      };
    this.questions.create(questionG).subscribe((res: any) => {
      this.toastService.success('Added successfully', '', { timeOut: 3000 });
      this.dialogRef.close(true);
      if (this.selectedFile) {
        this.s3Service.uploadFile(this.selectedFile, this.selectedFile.name);
      }

      //upload file here
      // }
    });
  }
  viewFile(url0: string) {
    const url1 = environment.s3Url + environment.s3Object;
    window.open(url1 + url0, '_blank');
  }
  onFileUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files; // This gives you access to the selected file(s)

    if (files && files.length > 0) {
      // You can now work with the selected file(s)
      this.selectedFile = files[0];
    }
    // this.file = event.target.files[0];
  }
  openOption() {
    this.dialog
      .open(OptionsAddComponent, {
        width: '500px',
      })
      .afterClosed()
      .subscribe((result) => {
        this.questionGListChanges$.next();
      });
  }

  get category(): AbstractControl<string | null> | null {
    return this.questionForm.get('category');
  }
  displayFn(unit: any): string {
    return unit && unit.title ? unit.title : '';
  }
}
