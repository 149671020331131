import { Component, OnInit, ViewChild, inject } from '@angular/core';
import {
  CoinTransaction,
  CoinTransactionService,
} from '../coin-transaction.service';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FilterDTO } from '../app.model';
import { AsyncPipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { UtcToLocalPipe } from './utc-to-local.pipe';
import { MatPaginator } from '@angular/material/paginator';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { User, UserService } from '../user.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-coin-transactions',
  standalone: true,
  imports: [
    AsyncPipe,
    MatTableModule,
    UtcToLocalPipe,
    MatPaginator,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInput,
  ],
  templateUrl: './coin-transactions.component.html',
  styleUrl: './coin-transactions.component.scss',
})
export class CoinTransactionsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  readonly coinTransactionService = inject(CoinTransactionService);
  readonly transactionTypeCtrl = new FormControl('All');
  coins$!: Observable<CdkTableDataSourceInput<CoinTransaction>>;
  totalElements = 0;
  readonly displayedColumns = [
    'id',
    'email',
    'amount',
    'coinFeature',
    'transactionType',
    'transactionDate',
  ];
  readonly userCtrl = new FormControl<string | null>(null);
  readonly userService = inject(UserService);
  readonly search$ = new Subject<void>();
  readonly route = inject(ActivatedRoute);
  readonly email = this.route.snapshot.queryParamMap.get('email');
  users$!: Observable<User[]> | undefined;
  ngOnInit(): void {
    this.userCtrl.setValue(this.email);
    this.users$ = this.userCtrl?.valueChanges.pipe(
      debounceTime(500),
      filter((s) => !!s),
      switchMap((s) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 10,
          search: `email:${s}*`,
        };
        return this.userService.filterData(filter).pipe(map((p) => p.elements));
      })
    );
    this.coins$ = merge(this.paginator.page, this.search$.asObservable()).pipe(
      startWith({}),
      switchMap(() => {
        let search = '';
        const type = this.transactionTypeCtrl.value;
        const user = this.userCtrl.value;
        if (type !== 'All') {
          search = `transactionType:${type}`;
        }
        if (user && user.length > 0) {
          if (search?.length > 0) {
            search += ' AND ';
          }
          search += `user.email:${user}`;
        }
        const f: FilterDTO = {
          pageNumber: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
          search: search,
          sorts: [
            {
              key: 'transactionDate',
              direction: 'DESC',
            },
          ],
        };
        return this.coinTransactionService.filterData(f).pipe(
          tap((p) => (this.totalElements = p.totalElements)),
          map((p) => p.elements)
        );
      })
    );
  }

  displayFn(user: any): string {
    return user && user.email ? user.email : '';
  }
}
