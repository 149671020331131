import { Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { QuestionsGroupComponent } from './questions/Questions-Group/questionsGroup.component';
import { QuestionSetComponent } from './questions/question-set/question-set.component';
import { QuestionsComponent } from './questions/questions/questions.component';
import { QuestionSetAddComponent } from './questions/question-set/question-set-add/question-set-add.component';
import { QuestionAddComponent } from './questions/questions/questions-add/questions-add.component';
import { OptionsComponent } from './options/options.component';
import { OptionsAddComponent } from './options/option-add/option-add.component';
import { OptionsEditComponent } from './options/option-edit/option-edit.component';
import { QuestionGroupEditComponent } from './questions/Questions-Group/question-group-edit/question-group-edit.component';
import { AuthGuard } from './auth.guard';
import { QuestionEditComponent } from './questions/questions/questions-edit/questions-edit.component';
import { QuestionSetEditComponent } from './questions/question-set/question-set-edit/question-set-edit.component';
import { SubChapterComponent } from './sub-chapter/sub-chapter.component';
import { SubChapterEditComponent } from './sub-chapter/sub-chapter-edit/sub-chapter-edit.component';
import { ExcelfileuploadComponent } from './excelfileupload/excelfileupload.component';
import { ConvertchapterComponent } from './convertchapter/convertchapter.component';
import { ReportComponent } from './report/report.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationEditComponent } from './notification-edit/notification-edit.component';
import { UnitListComponent } from './unit-list/unit-list.component';
import { QuestionSetQuestionsComponent } from './question-set-questions/question-set-questions.component';
import { CreateCoinComponent } from './create-coin/create-coin.component';
import { DiscussionForumComponent } from './discussion-forum/discussion-forum.component';
import { CoinTransactionsComponent } from './coin-transactions/coin-transactions.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { VectorStoreComponent } from './vector-store/vector-store.component';
import { SubscriptionListComponent } from './subscription-list/subscription-list.component';
import { UserListComponent } from './user-list/user-list.component';

export const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    data: { roles: ['ADMIN', 'EDITOR'] },
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'category',
        pathMatch: 'prefix',
      },
      {
        path: 'category',
        component: CategoryListComponent,
      },
      {
        path: 'chapters/:id',
        component: QuestionsGroupComponent,
      },

      { path: 'questions-edit/:id', component: QuestionEditComponent },
      {
        path: 'questionGroup-edit/:id',
        component: QuestionGroupEditComponent,
      },
      {
        path: 'question-sets/:id',
        component: QuestionSetComponent,
      },
      {
        path: 'sub-Chapter/:id',
        component: SubChapterComponent,
      },

      {
        path: 'units/:id',
        component: UnitListComponent,
      },
      {
        path: 'questionSet-edit/:id',
        component: QuestionSetEditComponent,
      },
      {
        path: 'notification-edit/:id',
        component: NotificationEditComponent,
      },
      {
        path: 'sub-chapter-edit/:id',
        component: SubChapterEditComponent,
      },
      {
        path: 'question-set-questions/:id',
        component: QuestionSetQuestionsComponent,
      },
      {
        path: 'questionsSets-add',
        component: QuestionSetAddComponent,
      },
      {
        path: 'questions-add',
        component: QuestionAddComponent,
      },
      {
        path: 'questions/:id',
        component: QuestionsComponent,
      },
      {
        path: 'options',
        component: OptionsComponent,
      },
      {
        path: 'excelupload',
        component: ExcelfileuploadComponent,
      },
      {
        path: 'convertchapter',
        component: ConvertchapterComponent,
      },

      {
        path: 'option-add',
        component: OptionsAddComponent,
      },
      {
        path: 'option-edit/:id',
        component: OptionsEditComponent,
      },
      {
        path: 'report',
        component: ReportComponent,
      },
      {
        path: 'notification',
        component: NotificationComponent,
      },
      {
        path: 'notification-edit',
        component: NotificationEditComponent,
      },
      {
        path: 'create-coin',
        component: CreateCoinComponent,
      },
      {
        path: 'forum',
        component: DiscussionForumComponent,
      },
      {
        path: 'coin-history',
        component: CoinTransactionsComponent,
      },
      {
        path: 'vector-store',
        component: VectorStoreComponent,
      },
      {
        path: 'subscription',
        component: SubscriptionListComponent,
      },
      {
        path: 'users',
        component: UserListComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];
