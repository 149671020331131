<div
  class="font-poppins flex mx-auto text-2xl text-[#C44055] text-center my-6 w-fit overflow-y-scroll"
  >
  Edit Notification
</div>

@if (notification$ | async; as SystemNotification) {
  <form
    [formGroup]="notificationForm"
    class="flex flex-col p-10 md:m-8 bg-[#295E84] rounded-xl gap-5"
    >
    <div class="flex flex-col grow flex-wrap mx-auto text-center gap-3 w-full">
      <div class="flex flex-wrap"></div>
      <mat-form-field>
        <mat-label class="">Title</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          placeholder="Enter Title"
          formControlName="title"
          required
        ></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label class="">Details</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          placeholder="Enter Details"
          formControlName="details"
          required
        ></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label class="">Priority</mat-label>
        <mat-select formControlName="priority">
          @for (priority of Priority; track priority) {
            <mat-option [value]="priority">
              {{ priority }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label class="">CreatedBY</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          placeholder="Enter CreatedBY"
          formControlName="createdBy"
          required
        ></textarea>
      </mat-form-field>
      <div class="flex flex-wrap mx-auto gap-3">
        <button
          [disabled]="notificationForm.invalid"
          type="button"
          (click)="submit()"
          mat-raised-button
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
          >
          Save
        </button>
        <a>
          <button
            type="button"
            mat-raised-button
            color="primary"
            class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
            (click)="goBack()"
            >
            Cancel
          </button>
        </a>
      </div>
    </div>
  </form>
}
<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
  >
</mat-paginator>
