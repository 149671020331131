import { CommonModule, JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { Subject, map, switchMap, tap } from 'rxjs';
import { Options } from 'src/app/questions/questions.model';
import { OptionsService } from '../options.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-option-edit',
  templateUrl: './option-edit.component.html',
  styleUrls: ['./option-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
  ],
})
export class OptionsEditComponent implements OnInit {
  constructor(
    private optionService: OptionsService,
    private dialogRef: MatDialog,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {}
  id: number | undefined;
  options$: any;
  ngOnInit(): void {
    this.options$ = this.route.params.pipe(
      map((params) => params['id']),
      tap((id) => (this.id = id)),
      switchMap((id) => this.optionService.getById(id)),
      tap((option) => this.questionForm.patchValue(option))
    );
  }
  questionGListChanges$ = new Subject<void>();
  questionForm = new FormGroup({
    name: new FormControl(''),
  });
  submit() {
    const question = this.questionForm.value;
    const questionG: Options = {
      name: question.name ?? '',
      correct: false,
    };
    this.optionService.update(this.id!, questionG).subscribe((res: any) => {
      this.toastr.success('Option edited Successfully', '', { timeOut: 3000 });
      this.questionGListChanges$.next();
      this.router.navigate(['/home/options']);
    });
  }
}
