import { CommonModule, JsonPipe } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import {
  CATEGORY,
  Level,
  Options,
  Question,
  S3Object,
} from '../../questions.model';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { QuestionService } from '../questions.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { FileUploadComponent } from 'src/app/file-upload/file-upload.component';
import { UploadFileComponent } from 'src/app/upload-file/upload-file.component';
import { S3Service } from 'src/app/s3.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { OptionsAddComponent } from 'src/app/options/option-add/option-add.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LANGUAGE } from '../questions-add/questions-add.component';
import { FilterDTO } from 'src/app/app.model';
import { Unit, UnitService } from 'src/app/unit.service';

@Component({
  selector: 'app-question-set-add',
  templateUrl: './questions-edit.component.html',
  styleUrls: ['./questions-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    UploadFileComponent,
    FileUploadComponent,
  ],
})
export class QuestionEditComponent implements OnInit {
  constructor(
    // @Inject(MAT_DIALOG_DATA) public _data: { id: number },

    private route: ActivatedRoute,
    private questions: QuestionService,
    private fb: FormBuilder,
    private s3Service: S3Service,
    private toastService: ToastrService,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
    private unitService: UnitService
  ) {
    this.questionForm = this.fb.group({
      title: new FormControl('', Validators.required),
      category: new FormControl('TEXT', Validators.required),
      level: new FormControl('', Validators.required),
      options: new FormControl(<Options[]>[], Validators.required),
      correctOption: new FormControl(<string | null>null, Validators.required),
      unit: new FormControl<Unit[] | null>(null, Validators.required),
      correctOptionReason: new FormControl('', Validators.required),
      language: new FormControl<string | null>(null),
    });
  }
  correctOptionCtrl = new FormControl('');
  questionForm: FormGroup;
  Levels = Object.keys(Level);
  Category = Object.keys(CATEGORY);
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  languages = [
    'java',
    'c',
    'cpp',
    'html',
    'javascript',
    'typescript',
    'php',
    'css',
  ];

  question: Question | null = null;
  readonly codeForm = this.fb.group({
    language: new FormControl<LANGUAGE>('java', Validators.required),
    snippet: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(5),
    ]),
  });
  optionCtrl = new FormControl('');
  idCtrl = new FormControl('');
  question$: any;
  fileUrl: string | null = null;
  selectedFile: File | null = null;
  questionGListChanges$ = new Subject<void>();

  units!: Observable<Unit[]> | undefined;
  ngOnInit(): void {
    this.units = this.questionForm.get('unit')?.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 5,
          search: `title:${v}*`,
        };
        return this.unitService
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );

    this.question$ = this.route.params.pipe(
      map((params) => params['id']),
      tap((id) => (this.id = id)),
      switchMap((id) => this.questions.getById(id)),
      tap((q) => {
        if (q.code) {
          this.questionForm.addControl('code', this.codeForm);
        }
      }),

      tap((q) => this.questionForm.patchValue(q)),
      tap((q) =>
        this.cOptions?.setValue(
          q?.options.find((o: Options) => o.correct)?.name
        )
      ),
      tap((q) => (this.question = q)),
      tap((q) => {
        if (q.file)
          this.fileUrl = environment.s3Url + environment.s3Object + q.file.key;
      })
    );
    this.category?.valueChanges
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((cat) => {
        if (cat === 'CODE') {
          this.questionForm.addControl('code', this.codeForm);
        } else {
          this.questionForm.removeControl('code');
        }
      });
  }
  onOptionEntered(optionInput: HTMLInputElement) {
    const value = optionInput.value.trim();
    if (value.length == 0) return;
    optionInput.value = '';

    const arrr = this.options?.value as Options[];

    if (arrr.length === 4) return;
    const i = arrr.findIndex((t) => t.name == value);
    if (i != -1) return;
    arrr.push({
      name: value,
      correct: false,
    });
  }

  onCorrectOptionSelected(option: string) {
    const arrr = this.options?.value as Options[];
    arrr.forEach((o) => (o.correct = o.name === option));
  }
  get options() {
    return this.questionForm.get('options');
  }

  get cOptions() {
    return this.questionForm.get('correctOption');
  }
  remove(i: number): void {
    const optionFc = this.options;
    const arr = (optionFc?.value as Options[]) ?? [];
    const v = arr[i];
    if (v.name === this.cOptions?.value) this.cOptions?.reset();

    arr.splice(i, 1);
    optionFc?.setValue(arr);
  }

  viewPrevFile() {
    window.open(this.fileUrl!, '_blank');
  }

  submit() {
    const question = this.questionForm.value;
    const questionValue = this.questionForm.getRawValue();
    let questionG: Question = {
      title: question.title ?? '',
      category: question.category ?? '',
      level: question.level ?? '',
      unit: question.unit,
      options: question.options ?? [],
      correctOptionReason: question.correctOptionReason ?? '',
      code: this.questionForm.get('code')?.value ?? null,
      // questionSetId: this._data.id ?? 0,
    };
    if (this.selectedFile) {
      let s3Obj: S3Object = {
        key: this.selectedFile.name,
        alt: this.selectedFile.name,
      };
      if (this.question?.file) {
        s3Obj = {
          ...s3Obj,
          id: this.question.file.id,
        };
      }
      questionG = {
        ...questionG,
        file: s3Obj,
      };
    }

    this.questions.update(this.id!, questionG).subscribe((res: any) => {
      this.toastService.success('Edited Sucessfully', '', { timeOut: 3000 });

      if (this.selectedFile) {
        this.s3Service.uploadFile(this.selectedFile, this.selectedFile.name);
        if (this.question?.file?.key)
          this.s3Service.removeFile(
            environment.s3Object + this.question?.file?.key
          );
      }
      history.back();
      //upload file here
      // }
    });
  }
  goBack() {
    history.back();
  }
  onFileUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files; // This gives you access to the selected file(s)

    if (files && files.length > 0) {
      // You can now work with the selected file(s)
      this.selectedFile = files[0];
    }
    // this.file = event.target.files[0];
  }
  openOption() {
    this.dialog
      .open(OptionsAddComponent, {
        width: '500px',
      })
      .afterClosed()
      .subscribe((result) => {
        this.questionGListChanges$.next();
      });
  }
  compareFn(c1: Options, c2: Options): boolean {
    return c1 && c2 ? c1.name === c2.name : c1 === c2;
  }

  get category(): AbstractControl<string | null> | null {
    return this.questionForm.get('category');
  }
  displayFn(unit: any): string {
    return unit && unit.title ? unit.title : '';
  }
}
