<div class="min-w-[768px] h-screen overflow-auto">
  <div
    class="font-poppins flex mx-auto text-2xl text-[#C44055] text-center my-6 w-fit"
  >
    Add Category
  </div>
  <form
    [formGroup]="categoryForm"
    class="flex flex-col m-6 p-3 sm:flex-row [&div>input]:bg-[#E8F9F0] bg-[#295E84] rounded-xl gap-5"
  >
    <div
      class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
    >
      <button (click)="addDetails()">Add details</button>
      <div>{{ _data.parentId }}</div>
      <div class="flex flex-wrap gap-8"></div>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          matInput
          placeholder="Enter name"
          formControlName="name"
          required
        />
      </mat-form-field>
      @if(hasDetails()){
      <h1>Details</h1>

      <div
        formGroupName="details"
        class="p-2 border-[6px] h-[50vh] overflow-scroll border-solid border-white flex flex-col justify-start gap-1"
      >
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Enter name"
            formControlName="name"
            required
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input
            matInput
            placeholder="Enter name"
            formControlName="title"
            required
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Exam Time</mat-label>
          <input
            matInput
            placeholder="Enter exam time(in mins)"
            formControlName="examTime"
            type="number"
            required
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Easy Questions</mat-label>
          <input
            matInput
            placeholder="Enter total easy questions"
            formControlName="easyQuestions"
            required
            type="number"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Hard Questions</mat-label>
          <input
            matInput
            placeholder="Enter total hard questions"
            formControlName="hardQuestions"
            required
            type="number"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Easy Marks</mat-label>
          <input
            matInput
            placeholder="Enter total hard questions"
            formControlName="easyMarks"
            required
            type="number"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Hard Marks</mat-label>
          <input
            matInput
            placeholder="Enter total hard questions"
            formControlName="hardMarks"
            required
            type="number"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Pass Marks</mat-label>
          <input
            matInput
            placeholder="Enter pass marks"
            formControlName="passMarks"
            required
            type="number"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Negative Marking</mat-label>
          <input
            matInput
            placeholder="Enter negative marking"
            formControlName="negativeMarking"
            required
            type="number"
            min="0"
            max="100"
          />
        </mat-form-field>
        <mat-checkbox
          class="example-margin self-start text-white"
          formControlName="mockupFree"
          >Mockup Fee</mat-checkbox
        >
        <mat-checkbox
        class="example-margin self-start text-white"
        formControlName="mockupReady"
        >Mockup Ready</mat-checkbox
      >
        
        <!-- Add other form controls here -->
        <button type="button" (click)="removeDetails()">Remove Details</button>
      </div>
      }
      <div class="flex flex-wrap mx-auto gap-3">
        <button
          (click)="submit()"
          [disabled]="categoryForm.invalid"
          mat-raised-button
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Save
        </button>
        <button
          mat-raised-button
          mat-dialog-close="false"
          color="primary"
          (click)="dialogRef.close(false)"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
