<mat-form-field class="w-full">
  <mat-label>Search Questions</mat-label>
  <input
    matInput
    #matInput
    aria-label="State"
    [matAutocomplete]="auto"
    [formControl]="questionNameCtrl"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="selectedQuestion.emit($event.option.value)"
  >
    @if (searchedQuestions | async; as questions) { @for (q of questions; track
    q.id) {
    <mat-option [value]="q" (click)="matInput.value = ''">
      <div class="flex flex-wrap gap-2 text-sm text-gray-600">
        <span><span class="font-medium">Id: </span>{{ q.id }} </span>
        <span
          ><span class="font-medium">Category: </span>{{ q.category }}
        </span>
        <span><span class="font-medium">Chapter: </span>{{ q.chapter }} </span>
        <span
          ><span class="font-medium">SubChapter: </span>{{ q.subChapter }}
        </span>
        <span><span class="font-medium">Unit: </span>{{ q.unit }} </span>
        <span><span class="font-medium">Set: </span>{{ q.set }} </span>
      </div>
      <div class="mb-2 text-lg font-medium">
        <span>{{ q.question }}</span>
      </div>
    </mat-option>
    }}
  </mat-autocomplete>
</mat-form-field>
