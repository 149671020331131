import { AsyncPipe } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  ActivatedRoute,
  RouterLink,
  RouterLinkWithHref,
} from '@angular/router';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { FilterDTO } from '../app.model';
import { SystemNotificationService } from '../notification.service';
import { NotificationaddComponent } from '../notificationadd/notificationadd.component';
import { SystemNotification } from './notification.model';
import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastrService } from 'ngx-toastr';
import { S3Service } from '../s3.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatIconModule,
    RouterLink,
    RouterLinkWithHref,
    AsyncPipe,
    MatPaginatorModule
],
})
export class NotificationComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  destoryRef = inject(DestroyRef);
  displayedColumns: string[] = [
    'id',
    'title',
    'details',
    'priority',
    'createdBy',
    'createdTime',
    'action',
  ];
  totalElements: number | undefined;
  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private systemNotificationService: SystemNotificationService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,
    private s3Service: S3Service
  ) {}
  destroy$ = new Subject<any>();
  id: number | undefined;
  notificationListChanges$ = new Subject<void>();
  notifications!: Observable<SystemNotification[]>;

  ngAfterViewInit(): void {
    this.notifications = merge(
      this.notificationListChanges$,
      this.paginator.page
    ).pipe(
      startWith({}),
      switchMap(() => {
        const filterDTO: FilterDTO = {
          pageNumber: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
        };
        return this.systemNotificationService.filterData(filterDTO).pipe(
          tap((page) => {
            this.totalElements = page.totalElements;
            this.cdr.detectChanges();
          }),
          map((page) => page.elements)
        );
      })
    );
  }
  deleteQuestion(notification: SystemNotification) {
    const delBtn = confirm(' Do you want to delete ?');
    if (delBtn == true) {
      this.systemNotificationService
        .delete(notification.id!)
        .pipe(takeUntilDestroyed(this.destoryRef))
        .subscribe(() => {
          this.toastrService.success(' Deleted Sucessfully', '', {
            timeOut: 3000,
          });
        });
      this.notificationListChanges$.next();
    }
  }
  openNotificationQuestion() {
    this.dialog
      .open(NotificationaddComponent, {
        data: {
          id: this.id,
        },
        width: '80vw',
        height: '90vh',
        panelClass: 'mx-auto',
      })
      .afterClosed()
      .subscribe((result) => {
        this.notificationListChanges$.next();
      });
  }
}
