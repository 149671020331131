<h1>Populate Question Vector Store</h1>
<form class="flex gap-2" [formGroup]="loadForm">
  <mat-form-field>
    <mat-label>Batch Size</mat-label>
    <input
      matInput
      placeholder="Enter batchSize"
      formControlName="batchSize"
      required
      type="number"
      min="1"
      max="5000"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Model</mat-label>
    <mat-select formControlName="model">
      @for (model of ['mxbai-embed-large']; track model) {
      <mat-option [value]="model">
        {{ model }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <button
    [disabled]="loadForm.invalid"
    mat-raised-button
    type="button"
    disableOnLoading="vector-store/load"
    (click)="load()"
  >
    Load
  </button>
</form>
<h1>Search Similar Question</h1>
<form class="flex gap-2" [formGroup]="searchForm">
  <mat-form-field class="min-w-[200px]">
    <mat-label>Search Query</mat-label>
    <textarea
      matInput
      placeholder="Enter search query"
      formControlName="search"
      required
      minlength="3"
      maxlength="300"
    ></textarea>
  </mat-form-field>
  <mat-form-field class="h-fit">
    <mat-label>Limit</mat-label>
    <input
      matInput
      placeholder="Enter limit"
      formControlName="limit"
      required
      type="number"
      min="1"
      max="50"
    />
  </mat-form-field>
  <button
    [disabled]="searchForm.invalid"
    mat-raised-button
    type="button"
    (click)="search()"
    disableOnLoading="vector-store/search"
  >
    Search
  </button>
</form>

@if(dataSource.length > 0){

<div class="overflow-x-scroll">
  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 bg-gray-100"
  >
    <!-- <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? toggleAllRows() :
                                null" [checked]="selection.hasValue() &&
                                isAllSelected()" [indeterminate]="selection.hasValue() &&
                                !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="id">
      <div class="flex gap-2">
        <th mat-header-cell *matHeaderCellDef>
          <div>ID</div>
        </th>
      </div>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        <div>Title</div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.question }}
      </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>
        <div>Category</div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.category }}
      </td>
    </ng-container>
    <ng-container matColumnDef="chapter">
      <th mat-header-cell *matHeaderCellDef>
        <div>Chapter</div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.chapter }}
      </td>
    </ng-container>
    <ng-container matColumnDef="subChapter">
      <th mat-header-cell *matHeaderCellDef>
        <div>Sub Chapter</div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.subChapter }}
      </td>
    </ng-container>

    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef>
        <div>Unit</div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.unit }}
      </td>
    </ng-container>
    <ng-container matColumnDef="set">
      <th mat-header-cell *matHeaderCellDef>
        <div>Set</div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.set }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="font-bold">Action</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <button>
            <a [routerLink]="['/home/questions-edit/' + element.id]" href="">
              <mat-icon>edit</mat-icon>
            </a>
          </button>
          <button (click)="deleteQuestion(element)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
}
