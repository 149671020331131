import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { getRoleFromKc } from '../Utils';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [MatIconModule, RouterLink, NgIf],
})
export class NavigationComponent {
  kc = inject(KeycloakService);

  readonly role = getRoleFromKc(this.kc);
  constructor() {}

  logout() {
    this.kc.logout().then(() => this.kc.login());
  }
}
