import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, effect, inject, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export type RouteLoading = {
  route: string;
  loading: boolean;
};
export type Branch = {
  name: string;
};
type AppConstant = {
  coinFeatures: Record<string, number>; // Equivalent to Map<String, Number>
  coinPackages: Record<string, Record<string, number>>; // Equivalent to Map<String, Map<String, Number>>
  faculties: Record<string, Branch[]>; // Equivalent to Map<String, Set<String>>
  serverTime: number; // Equivalent to Long (in Java, this is often represented as a number in TypeScript)
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private readonly loadingSubject$ = new BehaviorSubject<
    RouteLoading | undefined
  >(undefined);
  public readonly isLoading$ = this.loadingSubject$.asObservable();
  public appConstant$ = signal<AppConstant | undefined>(undefined);
  private readonly http;
  constructor(backend: HttpBackend) {
    this.http = new HttpClient(backend);
    this.loadAppConstantsHttp();
  }

  public setLoading(routeLoading: RouteLoading) {
    this.loadingSubject$.next(routeLoading);
  }

  private loadAppConstantsHttp() {
    return this.http
      .get<any>(`${environment.apiUrl}app/constants`)
      .subscribe((res) => this.appConstant$.set(res.body));
  }
}
