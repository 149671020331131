<div class="gap-5 flex justify-end">
  <a>
    <button
      (click)="openNotificationQuestion()"
      class="bg-[#04D9FF] text-white hover:bg-gray-300 focus:outline-none focus:bg-indigo-600 font-poppins text-xl px-5 py-2 rounded-lg"
    >
      Add Notifications
    </button>
  </a>
</div>

<div class="p-4">
  <div
    class="shadow-black bg-gray-100 border border-gray-400 rounded-lg mx-auto min-w-[300px]"
  >
    <div class="flex flex-wrap flex-row justify-between px-4"></div>
    <div class="overflow-x-scroll">
      <table
        mat-table
        [dataSource]="notifications"
        class="mat-elevation-z8 bg-gray-100"
      >
        <ng-container matColumnDef="id">
          <div class="flex gap-2">
            <th mat-header-cell *matHeaderCellDef>
              <div class="flex gap-2 font-bold">ID <button></button></div>
            </th>
          </div>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Title<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.title }}</td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Details<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.details }}
          </td>
        </ng-container>
        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Priority<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.priority }}</td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">CreatedBy<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
        </ng-container>

        <ng-container matColumnDef="createdTime">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">CreatedTime<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdTime }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="font-bold">Action</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <button>
                <a
                  [routerLink]="['/home/notification-edit/' + element.id]"
                  href=""
                >
                  <mat-icon>edit</mat-icon>
                </a>
              </button>
              <button (click)="deleteQuestion(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
>
</mat-paginator>
