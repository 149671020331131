import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { RestResponse, User } from '../../app.model';
import { QuestionGroup, QuestionSet } from '../questions.model';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionsSetService extends AbstractService<QuestionSet> {
  serviceName(): string {
    return 'question-sets';
  }
}
