import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'appendS3',
  standalone: true,
})
export class AppendS3Pipe implements PipeTransform {
  transform(value: string, includeS3Object = true): string {
    let url = environment.s3Url;
    if (includeS3Object) {
      url += environment.s3Object;
    }
    url += value;
    return url;
  }
}
