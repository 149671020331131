import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';

export interface Subscription {
  id?: number;
  userId: string;
  level: string;
}
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService extends AbstractService<Subscription> {
  override serviceName(): string {
    return 'subscription';
  }
}
