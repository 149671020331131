<div class="gap-5 flex justify-end">
  <a>
    <button
      (click)="openQuestion()"
      class="bg-[#04D9FF] text-white hover:bg-gray-300 focus:outline-none focus:bg-indigo-600 font-poppins text-xl px-5 py-2 rounded-lg"
    >
      Add Questions
    </button>
  </a>
</div>
<!-- <div class=" flex flex-row  gap-10">


    <a href="" [routerLink]="['/home/question/' + questionsG.id]" *ngFor="let questionsG of questionSet$ | async" class="h-48 w-56 flex flex-col gap-8 rounded-3xl border-2 border-[#04D9FF]

        p-4">
  <h1 class="line-clamp-2 text-center font-bold text-xl">{{questionsG.title}}</h1>
  <h2 class="line-clamp-3">{{questionsG.requiredCoins}}</h2>

</a>
</div> -->

<div class="p-4">
  <div class="text-lg my-2 text-gray-700">
    <a class="text-xl font-semibold" [routerLink]="['/home/questions-group']">Home ></a>
    <a class="text-xl font-semibold" [routerLink]="['/home/sub-Chapter',chapterId]" [queryParams]="{ chapter: chapter }">{{ chapter }}></a>
    <a class="text-xl font-semibold" [routerLink]="['/home/units',subChapterId]" [queryParams]="{ subChapter: subChapter, chapter: chapter }">{{ subChapter }} > </a>
    <a class="text-xl font-semibold" >{{ unit }} </a>
  </div>
  <div
    class="shadow-black bg-gray-100 border border-gray-400 rounded-lg mx-auto min-w-[300px]"
  >
    <div class="flex flex-wrap flex-row justify-between px-4">
      <div class="flex flex-wrap items-center sm:gap-14 gap-3 my-auto mx-3">
        <!-- <form class="flex relative flex-wrap">
        <mat-form-field class="flex-grow-1">
          <mat-label class="text-[#C44055]">Search......</mat-label>
          <input [formControl]="questionGroupNameCtrl" type="text" matInput>
        </mat-form-field>
                    <mat-icon class="text-gray-500 absolute right-0 top-[40%]
                            bottom-[50%] m-auto text-center">search</mat-icon>
      </form> -->
        <form class="example-form mt-6">
          <mat-form-field class="example-full-width">
            <mat-label>Search......</mat-label>
            <input [formControl]="questionCtrl" type="text" matInput />
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="overflow-x-scroll">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8 bg-gray-100"
      >
        <!-- <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? toggleAllRows() :
                                null" [checked]="selection.hasValue() &&
                                isAllSelected()" [indeterminate]="selection.hasValue() &&
                                !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container> -->

        <ng-container matColumnDef="id">
          <div class="flex gap-2">
            <th mat-header-cell *matHeaderCellDef>
              <div class="flex gap-2 font-bold">ID <button></button></div>
            </th>
          </div>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Title<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element" matTooltip="{{element.translation}}">
            {{ element.title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Category<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.category }}
          </td>
        </ng-container>
        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Level<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.level }}
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Options<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element" class="flex flex-row gap-2">
            @for (option of element.options; track option) {
            <div class="group">
              <div
                class="rounded-2xl p-2 mb-4 mt-4"
                [ngClass]="
                  option.correct
                    ? 'bg-green-600 text-white'
                    : 'bg-gray-400 text-black'
                "
              >
                {{ option.name }}
                <span
                  class="text-xl group-hover:opacity-100 opacity-0 transition-opacity duration-300 ease-in-out"
                >
                  <mat-icon
                    (click)="openOptionEditDialog(editOptionDialog, option)"
                    class="cursor-pointer"
                    >edit</mat-icon
                  >
                </span>
              </div>
            </div>
            }
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="correctOption">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex gap-2 font-bold">
          Correct Option<button></button>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.correctOption }}
      </td>
    </ng-container> -->
        <ng-container matColumnDef="correctOptionReason">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">
              Correct Option Reason<button></button>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.correctOptionReason }}
          </td>
        </ng-container>
        <ng-container matColumnDef="files">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Files<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            <button (click)="viewFile(element.file.key)">View File</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="font-bold">Action</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <button>
                <a
                  [routerLink]="['/home/questions-edit/' + element.id]"
                  href=""
                >
                  <mat-icon>edit</mat-icon>
                </a>
              </button>
              <button *ngIf="role === 'ADMIN'" (click)="deleteQuestion(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          [class]="row.id == buggedQuestionId ? '!bg-[#f0d9d9]' : ''"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </div>
</div>
<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="100"
  aria-label="Select page"
>
</mat-paginator>

<ng-template #editOptionDialog let-data>
  <div class="flex mx-auto text-xl md:text-2xl text-[#C44055] text-center">
    Edit Option
  </div>

  <form
    [formGroup]="questionSetForm"
    class="flex flex-col p-10 sm:flex-row [&div>input]:bg-[#E8F9F0] bg-[#295E84] rounded-xl gap-5"
  >
    <div
      class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
    >
      <div class="flex flex-wrap"></div>
      <mat-form-field class="mt-8">
        <mat-label>Title</mat-label>
        <input
          #input
          matInput
          [(ngModel)]="data.name"
          required
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
      <div class="flex flex-wrap mx-auto gap-3">
        <button
          (click)="editOption(data)"
          mat-raised-button
          [disabled]="data.name.length === 0"
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Save
        </button>
        <a>
          <button
            mat-raised-button
            mat-dialog-close
            color="primary"
            (click)="cancelOptionDialog()"
            class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
          >
            Cancel
          </button>
        </a>
      </div>
    </div>
  </form>
</ng-template>
