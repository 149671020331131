<div class="gap-5 flex flex-row justify-between mr-10 ml-10">
  <div
    class="font-poppins text-xl md:text-2xl text-[#C44055] text-center w-fit"
  >
    Unit
  </div>
  <a class="" (click)="openAddDialog()">
    <button
      class="bg-[#04D9FF] text-white hover:bg-gray-300 focus:outline-none focus:bg-indigo-600 font-poppins text-md md:text-xl px-2 md:px-5 py-2 rounded-lg"
    >
      Add Unit
    </button>
  </a>
</div>

<div class="p-4 w-full">
  <a class="text-xl font-semibold" [routerLink]="['/home/questions-group']"
    >Home ></a
  >
  <a
    class="text-xl font-semibold"
    [routerLink]="['/home/sub-Chapter', chapterId]"
    [queryParams]="{ chapter: chapter }"
    >{{ chapter }}></a
  >
  <a class="text-xl font-semibold">{{ subChapter }} </a>
  <div
    class="shadow-black bg-gray-100 border border-gray-400 rounded-lg mx-auto min-w-[300px] max-w-[1024px]"
  >
    <div class="flex flex-wrap flex-row justify-between px-4">
      <div class="flex flex-wrap items-center sm:gap-14 gap-3 my-auto mx-3">
        <form class="example-form mt-6">
          <mat-form-field class="example-full-width">
            <mat-label>Search......</mat-label>
            <input [formControl]="nameCtrl" type="text" matInput />
          </mat-form-field>
        </form>
      </div>
      <!-- <div class="flex p-2">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select [formControl]="categoryCtrl">
            <mat-option *ngFor="let categorys of Categorys" [value]="categorys">
              {{ categorys }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>
    <div class="overflow-x-scroll">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8 bg-gray-100"
      >
        <ng-container matColumnDef="id">
          <div class="flex gap-2">
            <th mat-header-cell *matHeaderCellDef>
              <div class="flex gap-2 font-bold">ID <button></button></div>
            </th>
          </div>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Title<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.title }}
          </td>
        </ng-container>
        <ng-container matColumnDef="questionSet">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Question Set</div>
          </th>

          <td mat-cell *matCellDef="let element">
            <div>
              <a
                [routerLink]="['/home/question-sets/' + element.id]"
                [queryParams]="{
                  chapter: chapter,
                  subChapter: subChapter,
                  unit: element.title
                }"
                href=""
              >
                <button mat-raised-button>View Set</button>
              </a>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="question">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Question</div>
          </th>

          <td mat-cell *matCellDef="let element">
            <div>
              <a
                [routerLink]="['/home/questions/' + element.id]"
                [queryParams]="{
                  chapter: chapter,
                  subChapter: subChapter,
                  unit: element.title
                }"
                href=""
              >
                <button mat-raised-button>View Questions</button>
              </a>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="upload">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Excel Upload</div>
          </th>

          <td mat-cell *matCellDef="let element">
            <div>
              <button
                (click)="openExcelUploadDialog(element.id)"
                mat-raised-button
              >
                Upload Excel
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="copy">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Unit Copy</div>
          </th>

          <td mat-cell *matCellDef="let element">
            <div>
              <button
                (click)="openCopyUnitDialog(element.id)"
                mat-raised-button
              >
                Copy Unit
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="font-bold">
            <div class="flex gap-2 font-bold">Action</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <button (click)="openEditDialog(element.id)">
                <mat-icon>edit</mat-icon>
              </button>
              <button
                disableOnLoading="units/{{ element.id }}"
                (click)="delete(element.id)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="10"
  aria-label="Select page"
>
</mat-paginator>
