<div class="gap-5 flex justify-between ml-4 md:mr-10 md:ml-10">
  <div
    class="font-poppins w-full text-[18px] md:text-2xl text-[#C44055] text-center md:my-6"
  >
    Copy Unit
  </div>
</div>
<form
  [formGroup]="questionForm"
  class="flex flex-col gap-2 w-fit mx-auto shadow-md border-[2px] border-gray-100 rounded-sm"
>
  <mat-form-field class="w-full">
    <mat-label>Enter destination unit Id</mat-label>
    <input
      disableOnLoading="questions/copy-unit/from"
      matInput
      #matInput
      formControlName="toUnitId"
      type="number"
    />
  </mat-form-field>
  <div class="flex gap-2">
    <button
      type="button"
      (click)="submit()"
      mat-raised-button
      [disabled]="questionForm.invalid"
      disableOnLoading="questions/copy-unit/from"
      color="primary"
      class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
    >
      Save
    </button>
    <button
      type="button"
      (click)="closeDialog()"
      mat-raised-button
      disableOnLoading="questions/copy-unit/from"
      [loadAnim]="false"
      color="primary"
      class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
    >
      Cancel
    </button>
  </div>
</form>
