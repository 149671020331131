<div class="gap-5 flex flex-col justify-between ml-4 md:mr-10 md:ml-10">
  <div
    class="font-poppins text-[18px] md:text-2xl text-[#C44055] text-center md:my-6 w-fit"
  >
    ExcelFile
  </div>

  <input
    type="file"
    (change)="handleFileInput($event)"
    placeholder="Insert file"
  />

  <div class="flex gap-2">
    <button
      type="button"
      (click)="submit()"
      mat-raised-button
      [disabled]="questionForm.invalid || !fileToUpload"
      disableOnLoading="questions/bulk-import-excel"
      color="primary"
      class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
    >
      Save
    </button>
    <button
      type="button"
      (click)="closeDialog()"
      mat-raised-button
      disableOnLoading="questions/bulk-import-excel"
      [loadAnim]="false"
      color="primary"
      class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
    >
      Cancel
    </button>
  </div>
</div>
