import { Component, DestroyRef, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatOption, MatSelectModule } from '@angular/material/select';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { QuestionService } from '../questions/questions/questions.service';
import { DisableOnLoadingDirective } from '../disable-on-loading.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-convertchapter',
  templateUrl: './convertchapter.component.html',
  styleUrls: ['./convertchapter.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    DisableOnLoadingDirective,
    MatAutocompleteModule,
    MatOption,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class ConvertchapterComponent {
  constructor(
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
    private toast: ToastrService,
    private questionService: QuestionService,
    @Inject(MAT_DIALOG_DATA) public data: { fromUnitId: number },
    private dialogRef: MatDialogRef<any>
  ) {
    this.questionForm = this.fb.group({
      fromUnitId: new FormControl(this.data.fromUnitId, [Validators.required]),
      toUnitId: new FormControl(null, [Validators.required]),
    });
  }
  questionForm: FormGroup;
  submit() {
    if (this.questionForm.valid) {
      const { fromUnitId, toUnitId } = this.questionForm.value;

      this.questionService
        .copyUnit(fromUnitId, toUnitId)

        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.toast.success('Copied Succesfully');
          this.dialogRef.close(true);
        });
    } else {
      this.toast.error('Invalid please try again');
      console.error('Form is invalid or file is not selected');
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
