<div class="font-poppins flex mx-auto text-2xl text-[#C44055] text-center my-6 w-fit">
    Add Option
</div>
<form [formGroup]="questionForm"
    class="flex flex-col m-8 p-10 sm:flex-row [&div>input]:bg-[#E8F9F0] bg-[#295E84] rounded-xl gap-5">
    <div class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full">
        <div class="flex flex-wrap"></div>
        <mat-form-field class="mt-8">
            <mat-label class="">Option Name</mat-label>
            <input matInput placeholder="Enter Option Name" formControlName="name" required />
        </mat-form-field>

        <div class="flex flex-wrap mx-auto gap-3">
            <button (click)="submit()" mat-raised-button color="primary"
                class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto">
                Save
            </button>
            <a>
                <button mat-raised-button mat-dialog-close color="primary"
                    class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto">
                    Cancel
                </button>
            </a>
        </div>
    </div>
</form>