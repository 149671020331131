<div
  class="font-poppins flex mx-auto text-2xl text-[#C44055] text-center my-6 w-fit overflow-y-scroll"
>
  Add Call History
</div>
<form
  [formGroup]="callHistoryForm"
  class="flex flex-col p-10 md:m-8 bg-[#295E84] rounded-xl gap-5"
>
  <div
    class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
  >
    <div class="flex flex-wrap"></div>

      <mat-form-field>
        <mat-label>Medium</mat-label>
        <mat-select formControlName="medium">
          @for (lang of medium; track lang) {
          <mat-option [value]="lang">
            {{ lang }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
  <div class="flex-row gap-3 flex self-start">
    <span class="text-[16px]">Received</span>
    <mat-slide-toggle class="flex self-start" formControlName="received"></mat-slide-toggle>
  </div>
      <mat-form-field>
        <mat-label class="">Notes </mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          placeholder="Enter Title"
          formControlName="notes"
          required
        ></textarea>
      </mat-form-field>
    <mat-form-field>
      <mat-label>Follow Up Date</mat-label>
      <input matInput
        (click)="picker.open()"
[matDatepickerFilter]="myFilter"
        [matDatepicker]="picker" formControlName="followUpDate" required />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
      <div class="flex flex-wrap mx-auto gap-3">
        <button
          type="button"
          [disabled]="callHistoryForm.invalid"
          (click)="submit()"
          mat-raised-button
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Save
        </button>
        <a>
          <button
            type="button"
            (click)="close()"
            mat-dialog-close
            color="primary"
            class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
          >
            Cancel
          </button>
        </a>
      </div>
  </div>
</form>
