import { Component, DestroyRef, Inject, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { Category, CategoryService } from '../category.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-category-add',
  standalone: true,
  imports: [
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatButtonModule,
    MatCheckbox,
  ],
  templateUrl: './category-add.component.html',
  styleUrl: './category-add.component.scss',
})
export class CategoryAddComponent {
  readonly categoryForm = new FormGroup({
    name: new FormControl(null, Validators.required),
    parentId: new FormControl(null),
    details: this.fb.group({}),
  });

  readonly destroyRef = inject(DestroyRef);
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { parentId: number },
    private categoryService: CategoryService,
    public dialogRef: MatDialogRef<CategoryAddComponent>,
    private fb: FormBuilder
  ) {}

  createDetails(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      title: ['', Validators.required],
      examTime: [null, [Validators.required, Validators.min(1)]],
      easyQuestions: [null, [Validators.required, Validators.min(0)]],
      hardQuestions: [null, [Validators.required, Validators.min(0)]],
      easyMarks: [null, [Validators.required, Validators.min(0)]],
      hardMarks: [null, [Validators.required, Validators.min(0)]],
      passMarks: [null, [Validators.required, Validators.min(0)]],
      mockupFree: [false],
      mockupReady: [false],
      negativeMarking: [-1, Validators.required],
    });
  }
  get details() {
    return this.categoryForm.get('details') as FormGroup;
  }

  addDetails() {
    this.categoryForm.setControl('details', this.createDetails());
  }

  removeDetails() {
    this.categoryForm.setControl('details', this.fb.group({}));
  }

  hasDetails(): boolean {
    return Object.keys(this.details.controls).length > 0;
  }
  submit() {
    const c = this.categoryForm.value;
    let cat: Category = {
      name: c.name ?? '',
      parentId: this._data.parentId ?? null,
    };
    if (this.hasDetails()) {
      cat = {
        ...cat,
        details: this.details.value,
      };
    }
    this.categoryService
      .create(cat)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.dialogRef.close(true));
  }
}
