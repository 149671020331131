<div class="gap-5 flex flex-row justify-between ml-10 mr-10">
    <div class="font-poppins text-2xl text-[#C44055] text-center
                w-fit ">Options</div>
    <a>
        <button (click)="openOption()" class="bg-[#04D9FF] text-white hover:bg-gray-300
                focus:outline-none focus:bg-indigo-600 font-poppins
                text-xl px-5 py-2 rounded-lg ">
            Add Option
        </button>
    </a>
</div>

<div class="p-4 w-full ">

    <div class=" shadow-black
            bg-gray-100
            border border-gray-400 rounded-lg ">
        <div class="flex flex-wrap flex-row justify-between px-4 ">
            <div class="flex flex-wrap items-center sm:gap-14 gap-3 my-auto
                    mx-3">

                <form class="example-form mt-2">
                    <mat-form-field class="example-full-width">
                        <mat-label>Search......</mat-label>
                        <input [formControl]="questionGroupNameCtrl" type="text" matInput>
                    </mat-form-field>

                </form>


            </div>

        </div>
        <div class="overflow-x-scroll">
            <table mat-table [dataSource]="dataSource" class=" mat-elevation-z8 bg-gray-100">

                <ng-container matColumnDef="id">
                    <div class="flex gap-2">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="flex gap-2 font-bold">
                                ID <button>
                                </button></div>
                        </th>
                    </div>
                    <td mat-cell *matCellDef="let element"> {{element.id}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="flex gap-2 font-bold">
                            Name<button> </button></div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="font-bold">
                        Action</th>
                    <td mat-cell *matCellDef="let
                            element">
                        <div>
                            <button>
                                <a [routerLink]="['/home/option-edit/' +
                                        element.id ]" href="">
                                    <mat-icon>edit</mat-icon>
                                </a>
                            </button>
                            <button (click)="deleteGroup(element.id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns:
                        displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
<mat-paginator #paginator class="demo-paginator" [length]="totalElements" [pageSize]="10" aria-label="Select page">
</mat-paginator>