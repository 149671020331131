import { CoinTransaction } from '../coin-transaction.service';
import { Observable, Subject } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { User, UserService } from '../user.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { UtcToLocalPipe } from '../coin-transactions/utc-to-local.pipe';
import { MatButton } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { KeycloakService } from 'keycloak-angular';
import { getRoleFromKc } from '../Utils';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Component, inject, ViewChild } from '@angular/core';
import { injectQueryParams } from 'ngxtension/inject-query-params';

@Component({
  selector: 'app-call-history',
  standalone: true,
  imports: [
    AsyncPipe,
    MatTableModule,
    UtcToLocalPipe,
    MatPaginator,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInput,
    MatButton,
    MatCheckboxModule,
    NgIf,
    RouterLink,
  ],

  templateUrl: './call-history.component.html',
  styleUrl: './call-history.component.scss',
})
export class CallHistoryComponent {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  readonly transactionTypeCtrl = new FormControl('All');
  coins$!: Observable<CdkTableDataSourceInput<CoinTransaction>>;
  users$!: Observable<CdkTableDataSourceInput<User>>;
  userId: string | undefined;
  readonly kc = inject(KeycloakService);
  readonly role = getRoleFromKc(this.kc);
  totalElements = 0;
  readonly displayedColumns = [
    'id',
    'calledDate',
    'calledBy',
    'notes',
    'medium',
    'received',
    'followUpDate',
  ];
  readonly userCtrl = new FormControl<string | null>(null);
  readonly userService = inject(UserService);
  readonly toast = inject(ToastrService);
  readonly search$ = new Subject<void>();
  usersForm!: FormGroup;
  user$!: Observable<User[]> | undefined;
  readonly queryParams = injectQueryParams();
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('id') || ''; // Extract userId
      console.log('User ID:', this.userId);
      this.getUserData(); // Fetch data using userId
    });
  }

  displayFn(user: any): string {
    return user && user.email ? user.email : '';
  }

  getUserData() {
    if (!this.userId) {
      console.warn('No userId found in URL');
      return;
    }
    this.users$ = this.userService.userContactHistory(this.userId);
  }

  update(id: string, callAttempted: boolean, called: boolean, notes: string) {
    this.userService.updateUserCall(id, callAttempted, called, notes);

    setTimeout(() => {
      this.getUserData();
    }, 100);
  }
}
