<div>
  <mat-form-field class="!min-w-[300px]">
    <mat-label>User</mat-label>
    <input
      type="text"
      placeholder="Enter user email"
      matInput
      [formControl]="searchCtrl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      [displayWith]="displayFn"
      #auto="matAutocomplete"
      (optionSelected)="selected.emit($event.option.value)"
    >
      @for (user of users$ | async; track user.id) {
      <mat-option [value]="user">
        <div class="border-b-[1px] border-gray-200">
          <span>
            {{ user.email }}
          </span>
          <span class="text-gray-400 text-sm">
            {{ user.firstName + " " + user.lastName }}
          </span>
        </div>
      </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</div>
