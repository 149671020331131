import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { SystemNotification } from './notification/notification.model';

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationService extends AbstractService<SystemNotification> {
  override serviceName(): string {
    return 'system-notifications';
  }
}
