import {
  AfterViewInit,
  Component,
  DestroyRef,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { DiscussionForumService, Forum } from '../discussion-forum.service';
import { FilterDTO, PageDTO } from '../app.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AppendS3Pipe } from '../append-s3.pipe';
import {
  MatButton,
  MatFabButton,
  MatMiniFabButton,
} from '@angular/material/button';
import { S3Service } from '../s3.service';
import { ToastrService } from 'ngx-toastr';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { merge, startWith, switchMap, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-discussion-forum',
  standalone: true,
  imports: [
    MatFormField,
    MatOption,
    MatSelect,
    MatLabel,
    FormsModule,
    ReactiveFormsModule,
    MatPaginator,
    AppendS3Pipe,
    MatIcon,
    MatMiniFabButton,
    MatFormFieldModule,
    MatInputModule,

  ],
  templateUrl: './discussion-forum.component.html',
  styleUrl: './discussion-forum.component.scss',
})
export class DiscussionForumComponent implements AfterViewInit {
  totalElements = 0;
  pageSize = 20;
  pageNumber = 0;
  readonly appService = inject(AppService);
  readonly forumService = inject(DiscussionForumService);
  readonly toastService = inject(ToastrService);
  readonly s3Service = inject(S3Service);
  readonly destroyRef = inject(DestroyRef);
  lastIds: Map<number, number> = new Map();
  readonly faculties = computed(
    () => this.appService.appConstant$()?.faculties
  );
  readonly facultyCtrl = new FormControl('ENGINEERING');
  readonly categoryCtrl = new FormControl(22);
  readonly queryParams = injectQueryParams();
  readonly messages: WritableSignal<PageDTO<Forum> | undefined> =
    signal(undefined);
    categoryForm: FormGroup;

constructor(private fb: FormBuilder, private route: ActivatedRoute

){
      this.categoryForm = this.fb.group({
        categoryId: new FormControl(null),
      });
    }
    categoryId: number | undefined; // Store the ID here
  ngAfterViewInit(): void {

    this.route.paramMap.subscribe((params) => {
      this.categoryId = Number(params.get('id')); // Extract 'id' from the route
      console.log('ID:', this.categoryId);
    });
    if (this.categoryId) {
      this.categoryCtrl.patchValue(this.categoryId);
    }
    this.getFaculties()
    this.loadMessage(this.categoryId);
    this.categoryCtrl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reset();
        this.loadMessage();
      });
  }
  reset() {
    this.lastIds.clear();
    this.pageNumber = 0;
  }

  loadMessage(categoryId?: number) {


    const faculty = this.facultyCtrl.value;
    const  category  = this.categoryCtrl.value;

     if(categoryId){
      const filter: FilterDTO = {
        pageNumber: 1,
        pageSize: this.pageSize,
        params: {
          categoryId: categoryId,
        },
      };

      this.forumService
      .filterData(filter)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.messages.set(res);
        this.totalElements = res.totalElements;
        if (res.elements.length > 0)
          this.lastIds.set(
            this.pageNumber + 1,
            res.elements[res.elements.length - 1].id
          );
      });
     }else{
      const filter: FilterDTO = {
        pageNumber: 1,
        pageSize: this.pageSize,
        params: {
          categoryId: category,
        },
      };
      this.forumService
      .filterData(filter)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.messages.set(res);
        this.totalElements = res.totalElements;
        if (res.elements.length > 0)
          this.lastIds.set(
            this.pageNumber + 1,
            res.elements[res.elements.length - 1].id
          );
      });
     }



  }

  handlePageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    this.loadMessage();
  }

  getFaculties(): string[] {
    const f = this.faculties();
    console.log("FAculties", f)
    if (f) return Object.keys(f);
    return [];
  }

  get categories() {
    const facultyValue = this.facultyCtrl.value;
    const f = this.faculties();
    if (f && typeof facultyValue === 'string' && f[facultyValue]) {
      return f[facultyValue]!;
    }
    return [];
  }

  deleteMessage(f: Forum, softDelete = false) {
    if (confirm('Are you sure you want to delete?')) {
      const img = f.img?.key;
      if (img && !softDelete) {
        this.s3Service.removeFile(img).subscribe(() => {
          this.deleteMsg(f.id, false);
        });
      } else {
        this.deleteMsg(f.id, softDelete);
      }
    }
  }
  deleteMsg(id: number, softDelete = true) {
    const obs$ = softDelete ? this.forumService.softDelete(id):this.forumService.delete(id);
    obs$.subscribe(() => {
      this.toastService.success('message was deleted');
      this.reset();
      this.loadMessage();
    });
  }
}
