import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CommonModule, JsonPipe } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { QuestionSet, Question, Options } from '../questions.model';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { FilterDTO } from 'src/app/app.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QuestionsSetService } from '../question-set/question-set.service';
import { QuestionAddComponent } from './questions-add/questions-add.component';
import { QuestionService } from './questions.service';
import { environment } from 'src/environments/environment';
import { S3Service } from 'src/app/s3.service';
import { ToastrService } from 'ngx-toastr';
import { OptionsService } from 'src/app/options/options.service';
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
  ],
})
export class QuestionsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild('myDialog') template!: TemplateRef<any>;
  private dialogRef!: MatDialogRef<any>;
  private optionDialogRef!: MatDialogRef<any>;
  destoryRef = inject(DestroyRef);
  openDialogWithTemplateRef() {
    this.dialogRef = this.dialog.open(this.template, { disableClose: true });
  }
  id: number | undefined;
  totalElements: number | undefined;
  questions$: Observable<Question[]> | undefined;
  destroy$ = new Subject<any>();
  questionSetNameCtrl = new FormControl<string>('');
  questionGListChanges$ = new Subject<void>();
  questionSetForm = new FormGroup({
    title: new FormControl(''),
    runningTime: new FormControl(),
    requiredCoins: new FormControl(),
    questionGroupId: new FormControl(''),
  });

  displayedColumns: string[] = [
    'id',
    'title',
    'category',
    'level',
    'options',
    'correctOptionReason',
    'files',
    'action',
  ];
  questionCtrl = new FormControl('');
  buggedQuestionId = this.route.snapshot.queryParams['buggedQuestionId'];
  dataSource!: MatTableDataSource<Question>;
  readonly unit = this.route.snapshot.queryParamMap.get('unit');
  readonly subChapter = this.route.snapshot.queryParamMap.get('subChapter');
  readonly chapter = this.route.snapshot.queryParamMap.get('chapter');
  chapterId: number | undefined;
  subChapterId: number | undefined;
  unitId: number | undefined;

  constructor(
    private dialog: MatDialog,
    private questionService: QuestionService,
    private questionSetService: QuestionsSetService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private s3Service: S3Service,
    private toastrService: ToastrService,
    private optionService: OptionsService
  ) {}
  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        map((params) => params['id']),
        filter((id) => id != null && id != undefined),
        tap((id) => (this.id = id)),
        switchMap((id) =>
          merge(this.questionGListChanges$, this.paginator.page).pipe(
            startWith({}),
            switchMap(() => {
              return this.questionService.filterByUnit(id).pipe(
                tap((page) => (this.totalElements = page.totalElements)),
                map((page) => page.elements)
              );
            })
          )
        )
      )
      .subscribe((question) => {
        this.dataSource = new MatTableDataSource(question);
        this.subChapterId = question[0].unit?.subChapter?.id;
        this.unitId = question[0].unit?.id;
        this.chapterId = question[0].unit?.subChapter?.questionGroup?.id;
        this.questionGListChanges$, this.dataSource._updateChangeSubscription();
        this.cdr.detectChanges();
      });
  }
  submit() {
    const question = this.questionSetForm.value;
    const questionG: QuestionSet = {
      title: question.title ?? '',
      runningTime: question.runningTime ?? '',
      requiredCoins: question.requiredCoins ?? '',
      unitId: this.id ?? 0,
    };
    this.questionSetService.create(questionG).subscribe((res: any) => {
      this.questionGListChanges$.next();
      // if (this.snackbar) {
      //   this.snackbar.success('Question Group added successfully');
      //   this.dialogRef.close();
      // }
    });
  }
  viewFile(url0: string) {
    const url1 = environment.s3Url + environment.s3Object;
    window.open(url1 + url0, '_blank');
  }

  deleteQuestion(question: Question) {
    const delBtn = confirm(' Do you want to delete ?');
    if (delBtn == true) {
      this.questionService
        .delete(question.id!)
        .pipe(takeUntilDestroyed(this.destoryRef))
        .subscribe(() => {
          this.toastrService.success(' Deleted Sucessfully', '', {
            timeOut: 3000,
          });
          this.s3Service.removeFile(environment.s3Object + question.file?.key);
        });
      this.questionGListChanges$.next();
    }
  }
  openQuestion() {
    this.dialog
      .open(QuestionAddComponent, {
        data: {
          id: this.id,
        },
        width: '80vw',
        height: '90vh',
        panelClass: 'mx-auto',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        this.questionGListChanges$.next();
      });
  }

  openOptionEditDialog(ref: TemplateRef<any>, option: Options) {
    this.optionDialogRef = this.dialog.open(ref, {
      data: option,
      disableClose: true,
    });
  }

  editOption(option: Options) {
    if (!option.id) return;
    this.optionService.update(option.id, option).subscribe(() => {
      this.toastrService.success('Option was edited');
      this.questionGListChanges$.next();
      this.optionDialogRef.close();
    });
  }

  cancelOptionDialog() {
    this.questionGListChanges$.next();
  }
}
