import { AfterViewInit, Component, DestroyRef, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { Bugreport } from '../bugreport/bugreport.model';
import { BugreportService } from '../bugreport.service';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  Subject,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { FilterDTO } from '../app.model';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatFormField,
  MatLabel,
  MatOption,
  MatSelect,
} from '@angular/material/select';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelect,
    MatOption,
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
  ],
})
export class ReportComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'id',
    'reportedBy',
    'questionId',
    'details',
    'status',
    'date',
    'action',
  ];
  totalElements: number | undefined;
  dataSource!: MatTableDataSource<Bugreport>;
  statusCtrl = new FormControl('OPEN');
  constructor(
    private bugreportService: BugreportService,
    private toast: ToastrService,
    private destroyRef: DestroyRef,
    private router: Router
  ) {}
  bugreportListChanges$ = new Subject<void>();
  bugreport!: Observable<Bugreport[]>;
  ngAfterViewInit(): void {
    this.bugreport = merge(
      this.bugreportListChanges$,
      this.statusCtrl.valueChanges,
      this.paginator.page
    ).pipe(
      startWith({}),
      switchMap(() => {
        const filterDTO: FilterDTO = {
          pageNumber: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
          search: `status:${this.statusCtrl.value}`,
        };
        return this.bugreportService.filterData(filterDTO).pipe(
          tap((page) => (this.totalElements = page.totalElements)),
          map((page) => page.elements)
        );
      })
    );
  }

  updateStatus(element: Bugreport) {
    this.bugreportService
      .update(element.id!, element)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.toast.success('Update status');
        this.bugreportListChanges$.next();
      });
  }

  goToQuestion(element: Bugreport) {
    this.router.navigate([`/home/questions/${element.unitId}`], {
      queryParams: {
        buggedQuestionId: element.questionId,
      },
    });
  }
}
