import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { JsonPipe } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';

import {
  Subject,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { FilterDTO } from 'src/app/app.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { Unit, UnitService } from '../unit.service';
import { UnitAddComponent } from '../unit-add/unit-add.component';
import { UnitEditComponent } from '../unit-edit/unit-edit.component';
import { ExcelfileuploadComponent } from '../excelfileupload/excelfileupload.component';
import { ConvertchapterComponent } from '../convertchapter/convertchapter.component';
import { DisableOnLoadingDirective } from '../disable-on-loading.directive';
@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatSelectModule,
    DisableOnLoadingDirective,
  ],
})
export class UnitListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild('myDialog') template!: TemplateRef<any>;
  destoryRef = inject(DestroyRef);
  displayedColumns: string[] = [
    'id',
    'title',
    'questionSet',
    'question',
    'upload',
    'copy',
    'action',
  ];
  dataSource!: MatTableDataSource<Unit>;
  nameCtrl = new FormControl();
  id: number | undefined;
  totalElements: number | undefined;
  destroy$ = new Subject<any>();
  listChanges$ = new Subject<void>();

  readonly chapter = this.route.snapshot.queryParamMap.get('chapter');
  readonly subChapter = this.route.snapshot.queryParamMap.get('subChapter');
  chapterId: number | undefined;
  subChapterId: number | undefined;
  constructor(
    private dialog: MatDialog,
    private unitService: UnitService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        map((params) => params['id']),
        filter((id) => id != null && id != undefined),
        tap((id) => (this.id = id)),
        switchMap((id) =>
          merge(
            this.nameCtrl.valueChanges,
            this.listChanges$,
            this.paginator.page
          ).pipe(
            startWith({}),
            switchMap(() => {
              let search = `subChapter.id:${id}`;
              if (this.nameCtrl.value) {
                search += ` AND title:${this.nameCtrl.value}*`;
              }
              const filterDTO: FilterDTO = {
                pageNumber: this.paginator.pageIndex + 1,
                pageSize: this.paginator.pageSize,
                search: search,
              };
              return this.unitService.filterData(filterDTO).pipe(
                tap((page) => (this.totalElements = page.totalElements)),
                map((page) => page.elements)
              );
            })
          )
        )
      )
      .subscribe((questionSet) => {
        this.dataSource = new MatTableDataSource(questionSet);
        this.subChapterId = questionSet[0].subChapter?.id;
        this.chapterId = questionSet[0].subChapter?.questionGroup?.id;
        this.dataSource._updateChangeSubscription();
        this.cdr.detectChanges();
      });
  }

  delete(id: number) {
    const delBtn = confirm(' Do you want to delete ?');
    if (delBtn == true) {
      this.unitService
        .delete(id)
        .pipe(takeUntilDestroyed(this.destoryRef))
        .subscribe(() => {
          this.toastService.success('Deleted Sucessfully', '', {
            timeOut: 3000,
          });
          this.listChanges$.next();
        });
    }
  }

  openAddDialog() {
    this.dialog
      .open(UnitAddComponent, {
        data: {
          id: this.id,
        },
        minWidth: '50vw',
        height: '100vh',
      })
      .afterClosed()
      .subscribe((result) => {
        this.listChanges$.next();
      });
  }

  openEditDialog(id: number) {
    this.dialog
      .open(UnitEditComponent, {
        data: {
          subChapterId: this.id,
          id: id,
        },
        minWidth: '50vw',
        height: '100vh',
      })
      .afterClosed()
      .subscribe((result) => {
        this.listChanges$.next();
      });
  }
  openExcelUploadDialog(unitId: number) {
    this.dialog.open(ExcelfileuploadComponent, {
      data: {
        unitId: unitId,
      },
      disableClose: true,
    });
  }
  openCopyUnitDialog(unitId: number) {
    this.dialog.open(ConvertchapterComponent, {
      data: {
        fromUnitId: unitId,
      },
      disableClose: true,
    });
  }
}
