<div
  class="font-poppins flex mx-auto text-2xl text-[#C44055] text-center my-6 w-fit overflow-y-scroll"
>
  Add Question
</div>
<form
  [formGroup]="questionForm"
  class="flex flex-col p-10 md:m-8 bg-[#295E84] rounded-xl gap-5"
>
  <div
    class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
  >
    <div class="flex flex-wrap"></div>
    <mat-form-field>
      <mat-label >Title</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        placeholder="Enter Title"
        formControlName="title"
        required
      ></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label >Translation</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        placeholder="Enter Translation of Title"
        formControlName="translation"
      ></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Category</mat-label>
      <mat-select formControlName="category">
        @for (category of Category; track category) {
        <mat-option [value]="category">
          {{ category }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (category?.value === 'CODE') {
    <div formGroupName="code" class="flex flex-col">
      <mat-form-field>
        <mat-label>Language</mat-label>
        <mat-select formControlName="language">
          @for (lang of languages; track lang) {
          <mat-option [value]="lang">
            {{ lang }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Code snippet</mat-label>
        <textarea
          matInput
          rows="14"
          placeholder="Enter code snippet"
          formControlName="snippet"
          requred
        ></textarea>
      </mat-form-field>
    </div>
    }
    @if (category?.value === 'PASSAGE') {
      <div formGroupName="passage" class="flex flex-col">

        <mat-form-field>
          <mat-label>Passage</mat-label>
          <textarea
            matInput
            rows="14"
            placeholder="Enter passage"
            formControlName="snippet"
            requred
          ></textarea>
        </mat-form-field>
      </div>
      }
    @if (category?.value != 'TEXT' && category?.value != 'CODE'  && category?.value != 'PASSAGE') {
    <div class="flex flex-col gap-2">
      <span>
        {{ selectedFile?.name }}
      </span>
      <input
        class="hidden"
        #fileUpload
        type="file"
        (change)="onFileUpload($event)"
        multiple="false"
      />
      <button class="bg-black p-2" type="button" (click)="fileUpload.click()">
        Upload File
      </button>
      @if (selectedFile) {
      <button class="bg-black p-2" type="button" (click)="selectedFile = null">
        Clear File
      </button>
      }
      <!-- <app-file-upload formControlName="file"></app-file-upload> -->
    </div>
    }

    <div class="flex flex-col">
      <div class="flex justify-between">
        <h2>Options</h2>
        <!-- <a>
        <button
          type="button"
          (click)="openOption()"
          class="bg-[#04D9FF] text-white hover:bg-gray-300 focus:outline-none focus:bg-indigo-600 font-poppins text-xl px-4 py-2 rounded-lg"
          >
          Add Option
        </button>
      </a> -->
      </div>
      <mat-form-field class="example-full-width">
        <mat-chip-set #chipGrid aria-label="Enter fruits">
          @for (o of options?.value; track o; let i = $index) {
          <mat-chip>
            {{ o.name }}
            <button type="button" matChipRemove (click)="remove(i)">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          }
        </mat-chip-set>
        <input
          #option
          type="text"
          matInput
          required
          minlength="2"
          [formControl]="optionCtrl"
          (keyup.enter)="onOptionEntered(option)"
        />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Correct Option </mat-label>
        <mat-select
          (selectionChange)="onCorrectOptionSelected($event.value)"
          formControlName="correctOption"
        >
          @for (option of questionForm.get('options')?.value; track option) {
          <mat-option [value]="option.name">{{ option.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Unit</mat-label>
        <input
          required
          type="text"
          matInput
          formControlName="unit"

        />
        <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          @for (u of units | async; track u.id) {
          <mat-option [value]="u"
            >{{ u.title }} [{{
              u.subChapter?.title +
                "<" +
                u.subChapter?.questionGroup?.title +
                "<" +
                u.subChapter?.questionGroup?.category
            }}]</mat-option
          >
          }
        </mat-autocomplete> -->
      </mat-form-field>
      <mat-form-field>
        <mat-label >Correct Option Reason</mat-label>
        <textarea
          matInput
          placeholder="Enter Correct Option Reason"
          formControlName="correctOptionReason"
          required
        ></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Level</mat-label>
        <mat-select formControlName="level">
          @for (level of Levels; track level) {
          <mat-option [value]="level">
            {{ level }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex flex-wrap mx-auto gap-3">
      <button
        type="button"
        [disabled]="questionForm.invalid"
        (click)="submit()"
        mat-raised-button
        color="primary"
        class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
      >
        Save
      </button>
      <a>
        <button
          type="button"
          mat-raised-button
          mat-dialog-close
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Cancel
        </button>
      </a>
    </div>
  </div>
</form>
