import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QuestionService } from '../questions/questions/questions.service';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { DisableOnLoadingDirective } from '../disable-on-loading.directive';

@Component({
  selector: 'app-excelfileupload',
  templateUrl: './excelfileupload.component.html',
  styleUrls: ['./excelfileupload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    UploadFileComponent,
    FileUploadComponent,
    MatAutocompleteModule,
    MatInputModule,
    DisableOnLoadingDirective,
  ],
})
export class ExcelfileuploadComponent implements OnInit {
  questionForm: FormGroup;
  constructor(
    private questionService: QuestionService,
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { unitId: number },
    private dialogRef: MatDialogRef<any>
  ) {
    this.questionForm = this.fb.group({
      unit: new FormControl(this.data.unitId),
    });
  }

  ngOnInit(): void {}
  fileToUpload: File | null = null;
  handleFileInput(event: any) {
    var files = event.target.files;
    if (files && files.length > 0) {
      this.fileToUpload = files.item(0);
    }
  }
  submit() {
    if (this.questionForm.valid && this.fileToUpload) {
      this.questionService
        .uploadExcel(this.fileToUpload, this.questionForm.value.unit)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.toast.success('Data saved successfully');
          this.dialogRef.close(true);
        });
    } else {
      this.toast.error('Form is invalid or file is not selected');
      console.error(
        'Please select file to upload',
        this.fileToUpload,
        this.questionForm.valid
      );
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
