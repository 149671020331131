import { Component, DestroyRef, Inject, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { Category, CategoryService } from '../category.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogRef } from '@angular/cdk/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-category-edit',
  standalone: true,
  imports: [
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatButtonModule,
    MatCheckbox,
  ],
  templateUrl: './category-edit.component.html',
  styleUrl: './category-edit.component.scss',
})
export class CategoryEditComponent {
  readonly categoryForm = new FormGroup({
    id: new FormControl<number | undefined>(undefined),
    name: new FormControl(null, Validators.required),
    parentId: new FormControl(null),
    details: this.fb.group({}),
  });

  readonly destroyRef = inject(DestroyRef);
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { categoryId: number },
    private categoryService: CategoryService,
    public dialogRef: MatDialogRef<CategoryEditComponent>,
    private fb: FormBuilder
  ) {
    this.categoryService.getById(this._data.categoryId).subscribe((v) => {
      if (v.details) {
        this.addDetails();
        this.categoryForm.patchValue(v as any);
      }
    });
  }

  createDetails(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      title: ['', Validators.required],
      examTime: [null, [Validators.required, Validators.min(1)]],
      easyQuestions: [null, [Validators.required, Validators.min(0)]],
      hardQuestions: [null, [Validators.required, Validators.min(0)]],
      easyMarks: [null, [Validators.required, Validators.min(0)]],
      hardMarks: [null, [Validators.required, Validators.min(0)]],
      passMarks: [null, [Validators.required, Validators.min(0)]],
      mockupFree: [false],
      negativeMarking: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
    });
  }
  get details() {
    return this.categoryForm.get('details') as FormGroup;
  }

  addDetails() {
    this.categoryForm.setControl('details', this.createDetails());
  }

  removeDetails() {
    this.categoryForm.setControl('details', this.fb.group({}));
  }

  hasDetails(): boolean {
    return Object.keys(this.details.controls).length > 0;
  }
  submit() {
    const c = this.categoryForm.value;
    this.categoryService
      .update(this._data.categoryId, c as any)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.dialogRef.close(true));
  }
}
