<h1>Users</h1>

<div class="flex gap-4">
  <mat-form-field class="w-[300px]">
    <mat-label>User</mat-label>
    <input
      type="text"
      placeholder="Enter user email"
      matInput
      [formControl]="emailCtrl"
      [matAutocomplete]="auto"
      class="min-w-[500px]"
    />
    <mat-autocomplete #auto="matAutocomplete">
      @for (user of users$ | async; track user.id) {
      <mat-option [value]="user.email">{{ user.email }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <button (click)="search$.next()">Search</button>
</div>
<table mat-table [dataSource]="(users$ | async) ?? []" class="mat-elevation-z8">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id.</th>
    <td mat-cell *matCellDef="let element cdkScrollable">
      <span class="w-[50px] line-clamp-1" [title]="element.id">
        {{element.id}}
      </span>
    
    </td>
  </ng-container>
  <ng-container matColumnDef="callAttempted">
    <th mat-header-cell *matHeaderCellDef>Phone Called</th>
    <td mat-cell *matCellDef="let element">
      <div class="flex flex-row gap-5">

        <mat-checkbox
        class="example-margin self-start w-[10px] text-white"
        [checked]="element.callAttempted"
        #callAttempted
        (change)="update(element.id,callAttempted.checked, element.called,element.calledNotes)"
  
        ></mat-checkbox>
        <mat-checkbox 
        class="example-margin self-start  w-[10px] text-white"
        [checked]="element.called"
        
        #phoneCalled
        (change)="update(element.id,element.callAttempted,phoneCalled.checked, element.calledNotes)"
        ></mat-checkbox>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element"> 
       
      {{element.email}}
  </td>
  </ng-container>
  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef>Notes</th>

    <td mat-cell *matCellDef="let element">
      <div class="flex flex-col gap-2 m-2">
        <textarea class="border-[1px]" [title]="element.calledNotes" #notes>{{element.calledNotes}}</textarea>
      
        <button class="flex self-end" (click)="update(element.id,element.callAttempted, element.called,notes.value)" mat-raised-button color="green">
          Update
       </button>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>First Name</th>
    <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
  </ng-container>
  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>Last Name</th>
    <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
  </ng-container>
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef>Phone</th>
    <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
  </ng-container>
  <ng-container matColumnDef="coins">
    <th mat-header-cell *matHeaderCellDef>Coins</th>
    <td mat-cell *matCellDef="let element">{{ element.coins }}</td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element">
      <span class="w-[80px] line-clamp-1" [title]="element.category?.name">
        {{element.category?.name}}
      </span>
      </td>
  </ng-container>

  <ng-container matColumnDef="createdTime">
    <th mat-header-cell *matHeaderCellDef>CreatedAt</th>
    <td mat-cell *matCellDef="let element">
      {{ element.createdTime | utcToLocal }}
    </td>
  </ng-container>
  <ng-container matColumnDef="update">
    <th mat-header-cell *matHeaderCellDef>Update</th>
    <td mat-cell *matCellDef="let element">


    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <button (click)="deleteUser(element.id)" mat-raised-button color="warn">
        delete
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
>
</mat-paginator>
