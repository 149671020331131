import { Directive, ElementRef, Renderer2, input } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { AppService } from './app.service';

@Directive({
  selector: '[disableOnLoading]',
  standalone: true,
})
export class DisableOnLoadingDirective {
  readonly disableOnLoading = input.required<
    `${string}` | `${string}/${string}` | `${string}/${string}/${string}`
  >();
  readonly loadAnim = input(true);
  private loadingSubscription!: Subscription;
  private isDisabled: boolean = false;

  constructor(
    private el: ElementRef,
    private appService: AppService,
    private renderer: Renderer2
  ) {}
  ngOnInit() {
    this.loadingSubscription = this.appService.isLoading$
      .pipe(filter((r) => r !== undefined))
      .subscribe((rl) => {
        if (!rl) return;
        const { route, loading } = rl;
        if (route.startsWith(this.disableOnLoading())) {
          this.isDisabled = loading;
          this.updateElementState();
        }
      });
    this.initialHTML = this.el.nativeElement.innerHTML;
  }

  ngOnDestroy() {
    // Clean up the subscription
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

  initialHTML!: string;
  private updateElementState() {
    if (this.isDisabled) {
      this.el.nativeElement.setAttribute('disabled', 'true');
      this.el.nativeElement.classList.add('disabled'); // Optional: Add a CSS class for styling
      if (this.loadAnim()) {
        this.renderer.setProperty(
          this.el.nativeElement,
          'innerHTML',
          `<div class="flex items-center justify-center">
     <span class="mr-1">${this.initialHTML}</span>
     <div class="animate-spin h-4 w-4 border-2 border-t-transparent border-blue-500 rounded-full"></div>
   </div>`
        );
      }
    } else {
      this.el.nativeElement.removeAttribute('disabled');
      this.el.nativeElement.classList.remove('disabled');
      this.renderer.setProperty(
        this.el.nativeElement,
        'innerHTML',
        this.initialHTML
      );
    }
  }
}
