import { JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterLinkWithHref } from '@angular/router';
import { Subject } from 'rxjs';
import { QuestionGroupService } from 'src/app/questions/Questions-Group/questions.service';
import { Options, QuestionGroup } from 'src/app/questions/questions.model';
import { OptionsService } from '../options.service';
import { Dialog } from '@angular/cdk/dialog';
import { ToastrModule, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-option-add',
  templateUrl: './option-add.component.html',
  styleUrls: ['./option-add.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    ToastrModule
],
})
export class OptionsAddComponent {
  constructor(
    private optionService: OptionsService,
    private dialogRef: MatDialogRef<OptionsAddComponent>,
    private toastr: ToastrService
  ) {}
  questionGListChanges$ = new Subject<void>();
  questionForm = new FormGroup({
    name: new FormControl(''),
  });
  submit() {
    const question = this.questionForm.value;
    const questionG: Options = {
      name: question.name ?? '',
      correct: false,
    };
    this.optionService.create(questionG).subscribe((res: any) => {
      this.toastr.success('Option Added Successfully', '', { timeOut: 3000 });
      this.questionGListChanges$.next();
      this.dialogRef.close();
    });
  }
}
