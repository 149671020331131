<h1>Subscriptions</h1>

<div class="flex gap-4">
  <mat-form-field>
    <mat-label>Choose subscription type</mat-label>
    <mat-select [formControl]="subscriptionTypeCtrl">
      @for(item of ['ALL', 'BASIC', 'STANDARD', 'PREMIUM']; track item){
      <mat-option [value]="item"> {{ item }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="w-[300px]">
    <mat-label>User</mat-label>
    <input
      type="text"
      placeholder="Enter user email"
      matInput
      [formControl]="userCtrl"
      [matAutocomplete]="auto"
      class="min-w-[500px]"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (user of users$ | async; track user.id) {
      <mat-option [value]="user">
<div class="border-b-[1px] border-gray-200">
            <span>
          {{ user.email }}
            </span>
            <span class="text-gray-400 text-sm">
            {{user.firstName + " " + user.lastName}}
            </span>
            </div>
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <button (click)="search$.next()">Search</button>
  <button *ngIf="role === 'ADMIN'" class="bg-red-200 p-4 ml-20 h-fit w-fit" (click)="purchase()">
    Purchase
  </button>
</div>
<table
  mat-table
  [dataSource]="(subscriptions$ | async) ?? []"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id.</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef>Cost</th>
    <td mat-cell *matCellDef="let element">{{ element.cost }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef>Level</th>
    <td mat-cell *matCellDef="let element">{{ element.level }}</td>
  </ng-container>
  <ng-container matColumnDef="validDays">
    <th mat-header-cell *matHeaderCellDef>Valid Days</th>
    <td mat-cell *matCellDef="let element">{{ element.validDays }}</td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef>Start Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.startDate | utcToLocal }}
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button (click)="openEditDialog(editDialog, element)" *ngIf="role === 'ADMIN' "> Edit </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
>
</mat-paginator>


<ng-template #editDialog let-data>
  <div class="flex mx-auto text-xl md:text-2xl text-[#C44055] text-center">
    Edit
  </div>

  <form
    [formGroup]="subscriptionForm"
    class="flex flex-col p-10 sm:flex-row [&div>input]:bg-[#E8F9F0] bg-[#295E84] rounded-xl gap-5"
  >
    <div
      class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
    >
      <div class="flex flex-wrap"></div>
      <input type="number" hidden formControlName="id" [(ngModel)]="data.id" />
  <mat-form-field>
    <mat-label>Choose subscription type</mat-label>
    <mat-select [(ngModel)]="data.level" formControlName="level">
      @for(item of ['ALL', 'BASIC', 'STANDARD', 'PREMIUM']; track item){
      <mat-option [value]="item"> {{ item }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Valid Days</mat-label>
      <input matInput type="number" formControlName="validDays" [(ngModel)]="data.validDays" required min="1" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Cost</mat-label>
      <input matInput type="number" formControlName="cost" [(ngModel)]="data.cost" required min="1" />
  </mat-form-field>
      <div class="flex flex-wrap mx-auto gap-3">
        <button
          (click)="updateSubs(data.id,data)"
          mat-raised-button
          [disabled]="subscriptionForm.invalid"
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Save
        </button>
        <a>
          <button
            mat-raised-button
            mat-dialog-close
            color="primary"
            (click)="closeDialog()"
            class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
          >
            Cancel
          </button>
        </a>
      </div>
    </div>
  </form>
</ng-template>
