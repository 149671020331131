import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { S3Object } from './questions/questions.model';
import { Observable } from 'rxjs';

export type Forum = {
  id: number;
  message: string;
  img?: S3Object;
  firstName: string;
  lastName: string;
  email: string;
  profilePic: string;
  createdTime: string;
  userId: string;
};
@Injectable({
  providedIn: 'root',
})
export class DiscussionForumService extends AbstractService<Forum> {
  override serviceName(): string {
    return 'forums';
  }

  public softDelete(id: string | number): Observable<Forum> {
    return this.http.delete<Forum>(`${this.BASE_URL}/${id}`);
  }
  public override delete(id: string | number): Observable<Forum> {
    return this.http.delete<Forum>(`${this.BASE_URL}/admin/${id}`);
  }
}
