import { Component, DestroyRef, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';
import {
  SimilaritySearchRequest,
  VectorLoadRequest,
  VectorStoreService,
} from '../vector-store.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastrService } from 'ngx-toastr';
import { Question } from '../questions/questions.model';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import { QuestionService } from '../questions/questions/questions.service';
import { S3Service } from '../s3.service';
import { RouterLink } from '@angular/router';
import { OptionsService } from '../options/options.service';
import { NgClass } from '@angular/common';
import { DisableOnLoadingDirective } from '../disable-on-loading.directive';

@Component({
  selector: 'app-vector-store',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatLabel,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatIcon,
    RouterLink,
    NgClass,
    DisableOnLoadingDirective,
  ],
  templateUrl: './vector-store.component.html',
  styleUrl: './vector-store.component.scss',
})
export class VectorStoreComponent {
  readonly loadForm = new FormGroup({
    batchSize: new FormControl(200),
    model: new FormControl('mxbai-embed-large'),
  });
  readonly searchForm = new FormGroup({
    search: new FormControl(''),
    limit: new FormControl(10),
  });
  readonly vectorService = inject(VectorStoreService);
  readonly questionService = inject(QuestionService);
  readonly optionService = inject(OptionsService);
  readonly s3Service = inject(S3Service);
  readonly destroyRef = inject(DestroyRef);
  readonly toast = inject(ToastrService);
  dataSource: Question[] = [];
  displayedColumns: string[] = [
    'id',
    'title',
    'category',
    'chapter',
    'subChapter',
    'unit',
    'set',
    'action',
  ];

  load() {
    const v = this.loadForm.value;
    this.vectorService
      .loadStore(v as VectorLoadRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.toast.success('loaded'));
  }
  search() {
    const v = this.searchForm.value;
    this.vectorService
      .searchSimilar(v as SimilaritySearchRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((q) => (this.dataSource = q));
  }

  deleteQuestion(question: Question) {
    const delBtn = confirm(' Do you want to delete ?');
    if (delBtn == true) {
      this.questionService
        .delete(question.id!)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.toast.success(' Deleted Sucessfully', '', {
            timeOut: 3000,
          });
          this.s3Service.removeFile(environment.s3Object + question.file?.key);
        });
    }
  }

  viewFile(url0: string) {
    const url1 = environment.s3Url + environment.s3Object;
    window.open(url1 + url0, '_blank');
  }
}
