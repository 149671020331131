import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { Category } from './category.service';
import { Observable } from 'rxjs';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  coins: number;
  category: Category;
  subscriptionLevel: string;
  phone: string;
}
@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractService<User> {
  override serviceName(): string {
    return 'users';
  }


  updateUserCall(id: string,callAttempted:boolean,called:boolean,  notes: string){
   console.log("Hello ", this.API_URL)
   
     this.http.put(`${this.BASE_URL}/${id}/phone`,{
      callAttempted: callAttempted,
      called: called,
      calledNotes: notes
    } ).subscribe()
  }
    
}
