import { Component, OnInit } from '@angular/core';
import { QuestionsSetService } from '../question-set.service';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { Subject, map, switchMap, tap } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { QuestionSet } from '../../questions.model';
import { ToastrService } from 'ngx-toastr';
import { CommonModule, JsonPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-question-set-edit',
  templateUrl: './question-set-edit.component.html',
  styleUrls: ['./question-set-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
  ],
})
export class QuestionSetEditComponent implements OnInit {
  constructor(
    private questionSetService: QuestionsSetService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router
  ) {}
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  questionSetForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    runningTime: new FormControl(),
    requiredCoins: new FormControl(),
  });
  questionSet$: any;
  ngOnInit(): void {
    this.questionSet$ = this.route.params.pipe(
      map((params) => params['id']),
      tap((id) => (this.id = id)),
      switchMap((id) => this.questionSetService.getById(id)),
      tap((question) => this.questionSetForm.patchValue(question))
    );
  }
  goback() {
    history.back();
  }
  submit() {
    const question = this.questionSetForm.value;
    const questionG: QuestionSet = {
      title: question.title ?? '',
      details: question.details?? '',
      runningTime: question.runningTime ?? '',
      requiredCoins: question.requiredCoins ?? '',
    };
    this.questionSetService
      .update(this.id!, questionG)
      .subscribe((res: any) => {
        this.toastrService.success('Question set Edited successfully');
        this.goback();
      });
  }
}
