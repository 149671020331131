import { AfterViewInit, Component, ViewChild, inject } from '@angular/core';
import { CoinTransaction } from '../coin-transaction.service';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FilterDTO } from '../app.model';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { User, UserService } from '../user.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { UtcToLocalPipe } from '../coin-transactions/utc-to-local.pipe';
import { MatButton } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { KeycloakService } from 'keycloak-angular';
import { getRoleFromKc } from '../Utils';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UserSearchComponent } from '../user-search/user-search.component';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    AsyncPipe,
    MatTableModule,
    UtcToLocalPipe,
    MatPaginator,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInput,
    MatButton,
    MatCheckboxModule,
    NgIf,
    RouterLink,
    NgClass,
    UserSearchComponent,
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  readonly transactionTypeCtrl = new FormControl('All');
  coins$!: Observable<CdkTableDataSourceInput<CoinTransaction>>;
  users$!: Observable<CdkTableDataSourceInput<User>>;

  readonly kc = inject(KeycloakService);
  readonly role = getRoleFromKc(this.kc);
  totalElements = 0;
  readonly displayedColumns = [
    'callHistory',
    'email',
    'fullName',
    'callAttempted',
    'phone',
    'createdTime',
    'notes',
    'coins',
    'category',
    'subscriptionLevel',
    'subscriptionStartDate',
    'subscriptionValidDays',
    'id',
    'action',
  ];
  readonly userCtrl = new FormControl<User | null>(null);
  readonly userService = inject(UserService);
  readonly toast = inject(ToastrService);
  readonly search$ = new Subject<void>();
  usersForm!: FormGroup;

  readonly route = inject(ActivatedRoute);
  readonly router = inject(Router);
  readonly pageNumber =
    Number(this.route.snapshot.queryParamMap.get('pageNumber') ?? 0) - 1;
  readonly email = this.route.snapshot.queryParamMap.get('email');
  ngAfterViewInit(): void {
    this.userCtrl.setValue({ email: this.email } as User);
    this.getUserData();
  }

  pageChanged(event: PageEvent) {
    this.addQueryParam({ pageNumber: event.pageIndex + 1 });
  }
  clearUserSearch() {
    this.userCtrl.setValue(null);
    this.search$.next();
    this.addQueryParam({ email: null });
  }
  displayFn(user: any): string {
    return user && user.email ? user.email : '';
  }
  getUserData() {
    this.users$ = merge(this.paginator.page, this.search$.asObservable()).pipe(
      startWith({}),
      switchMap(() => {
        const user = this.userCtrl.value;
        let params = {};
        if (user && user.email && user.email.length > 2) {
          //@ts-ignore
          params['email'] = user.email;
          this.addQueryParam({ email: user.email });
        }
        const f: FilterDTO = {
          pageNumber: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
          params: params,
        };
        return this.userService.filterData(f).pipe(
          tap((p) => (this.totalElements = p.totalElements)),
          map((p) => p.elements)
        );
      })
    );
  }
  deleteUser(id: string) {
    const y = confirm('Are you sure you want to delete the user?');
    if (y) {
      this.userService.delete(id).subscribe(() => {
        this.toast.success('deleted');
        this.search$.next();
      });
    }
  }
  update(id: string, callAttempted: boolean, called: boolean, notes: string) {
    this.userService.updateUserCall(id, callAttempted, called, notes);

    setTimeout(() => {
      this.getUserData();
    }, 100);
  }

  selectUser(user: User) {
    console.log('selected', user);
    this.userCtrl.setValue(user);
    this.addQueryParam({ email: user.email });
  }
  addQueryParam(keyV: Record<string, any | null>) {
    const currentParams = this.route.snapshot.queryParams;

    const newParams = {
      ...currentParams,
      ...keyV,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: newParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });
  }
}
