<h1>Users</h1>

<div class="flex gap-4 place-items-center">
  <user-search
(selected)="selectUser($event)"
    />
  <div class="flex gap-2">
  <button mat-raised-button (click)="search$.next()">Search</button>
  <button mat-stroked-button color="warn" (click)="clearUserSearch()">Clear</button>
    </div>
  </div>
<div class="max-w-[85vw] max-h-[75vh]  overflow-scroll">
  <table mat-table [dataSource]="(users$ | async) ?? []" class=" mat-elevation-z8">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id.</th>
    <td mat-cell *matCellDef="let element cdkScrollable">
      <span class="w-[50px] line-clamp-1" [title]="element.id">
        {{element.id}}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="callAttempted">
    <th mat-header-cell *matHeaderCellDef>Last Called Date</th>
    <td mat-cell *matCellDef="let element">
      <span class="w-[50px] " [title]="element.lastCalledDate">
        @if (element.lastCalledDate==null) {
          {{element.lastCalledDate}}
        }@else {
          {{element.lastCalledDate | utcToLocal}}

        }
      </span>
      <div class="flex flex-row gap-5">

        <!-- <mat-checkbox
        class="example-margin self-start w-[10px] text-white"
        [checked]="element.callAttempted"
        #callAttempted
        (change)="update(element.id,callAttempted.checked, element.called,element.calledNotes)"

        ></mat-checkbox>
        <mat-checkbox
        class="example-margin self-start  w-[10px] text-white"
        [checked]="element.called"

        #phoneCalled
        (change)="update(element.id,element.callAttempted,phoneCalled.checked, element.calledNotes)"
        ></mat-checkbox> -->
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="email" sticky>
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">

      {{element.email}}
  </td>
  </ng-container>
  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef>Notes</th>

    <td mat-cell *matCellDef="let element">
      <span class="w-[80px] line-clamp-2" [title]="element.lastCalledNotes">
        {{element.lastCalledNotes}}
      </span>
      </td>


  </ng-container>
  <ng-container matColumnDef="fullName">
    <th mat-header-cell *matHeaderCellDef>Full Name</th>
    <td mat-cell *matCellDef="let element">{{ element.firstName + " " + element.lastName }}</td>
  </ng-container>
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef>Phone</th>
    <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
  </ng-container>
  <ng-container matColumnDef="coins">
    <th mat-header-cell *matHeaderCellDef>Coins</th>
    <td mat-cell *matCellDef="let element">{{ element.coins }}</td>
  </ng-container>
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element">
      <span class="w-[80px] line-clamp-1" [title]="element.category">
        {{element.category}}
      </span>
      </td>
  </ng-container>

  <ng-container matColumnDef="createdTime">
    <th mat-header-cell *matHeaderCellDef>CreatedAt</th>
    <td mat-cell *matCellDef="let element">
      {{ element.createdTime | utcToLocal }}
    </td>
  </ng-container>
  <ng-container matColumnDef="subscriptionLevel">
    <th mat-header-cell *matHeaderCellDef>Subscription Level</th>
    <td mat-cell *matCellDef="let element">{{ element.subscriptionLevel }}</td>
  </ng-container>
  <ng-container matColumnDef="subscriptionStartDate">
    <th mat-header-cell *matHeaderCellDef>Subscription Start Date</th>
    <td mat-cell *matCellDef="let element">{{ element.subscriptionStartDate | utcToLocal }}</td>
  </ng-container>
  <ng-container matColumnDef="subscriptionValidDays">
    <th mat-header-cell *matHeaderCellDef>Subscription Valid Days</th>
    <td mat-cell *matCellDef="let element">{{ element.subscriptionValidDays }}</td>
  </ng-container>
  <ng-container matColumnDef="update">
    <th mat-header-cell *matHeaderCellDef>Update</th>
    <td mat-cell *matCellDef="let element">


    </td>
  </ng-container>

  <ng-container matColumnDef="callHistory" sticky>
    <th mat-header-cell *matHeaderCellDef>History</th>
    <td mat-cell *matCellDef="let element">
      <div class="grid grid-cols-2 w-[180px] gap-1 py-1">
        <button mat-stroked-button
          [routerLink]="['/home/coin-history']" [queryParams]="{email: element.email}"
          class="px-4  py-2 rounded-md text-[14px]">
        coins
        </button>
        <button mat-stroked-button [routerLink]="['/home/callHistory', element.id]" class="px-4 py-2 rounded-md text-[14px]">
        call
        </button>
        <button
          [routerLink]="['/home/subscription']" [queryParams]="{email: element.email}"
          mat-stroked-button class="px-4  py-2 rounded-md text-[14px]">
        subs
        </button>
        <button
          [routerLink]="['/home/userActivity']" [queryParams]="{email: element.email}"
          mat-stroked-button class="px-4  py-2 rounded-md text-[14px]">
          activity
        </button>
        </div>

    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <button *ngIf="role === 'ADMIN'" (click)="deleteUser(element.id)" mat-stroked-button color="warn">
        delete
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"
      [class]="{
        'premium-row': row.subscriptionLevel === 'PREMIUM',
        'standard-row': row.subscriptionLevel === 'STANDARD',
        'basic-row': row.subscriptionLevel === 'BASIC'
      }">
  </tr></table>
  </div>

<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  [pageIndex]="pageNumber"
  aria-label="Select page"
  (page)="pageChanged($event)"
>
</mat-paginator>
