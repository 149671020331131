import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';

export interface Category {
  id?: number;
  name: string;
  details?: any;
  parentId: number | null;
  leaf?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class CategoryService extends AbstractService<Category> {
  override serviceName(): string {
    return 'categories';
  }
}
