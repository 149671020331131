import { Injectable } from '@angular/core';
import { Bugreport } from './bugreport/bugreport.model';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class BugreportService extends AbstractService<Bugreport> {
  override serviceName(): string {
    return 'bug-reports';
  }
}
