<h1>Coin Transactions</h1>

<div class="flex gap-4">
  <mat-form-field>
    <mat-label>Choose transaction type</mat-label>
    <mat-select [formControl]="transactionTypeCtrl">
      @for(item of ['All', 'BUY', 'SPEND']; track item){
      <mat-option [value]="item"> {{ item }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="w-[300px]">
    <mat-label>User</mat-label>
    <input
      type="text"
      placeholder="Enter user email"
      matInput
      [formControl]="userCtrl"
      [matAutocomplete]="auto"
      class="min-w-[500px]"
    />
    <mat-autocomplete #auto="matAutocomplete">
      @for (user of users$ | async; track user.id) {
      <mat-option [value]="user.email">{{ user.email }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <button (click)="search$.next()">Search</button>
</div>
<table mat-table [dataSource]="(coins$ | async) ?? []" class="mat-elevation-z8">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id.</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="transactionType">
    <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
    <td mat-cell *matCellDef="let element">{{ element.transactionType }}</td>
  </ng-container>
  <ng-container matColumnDef="coinFeature">
    <th mat-header-cell *matHeaderCellDef>For</th>
    <td mat-cell *matCellDef="let element">{{ element.coinFeature }}</td>
  </ng-container>

  <ng-container matColumnDef="transactionDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.transactionDate | utcToLocal }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
>
</mat-paginator>
