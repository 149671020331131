<div class="gap-5 flex justify-between ml-4 md:mr-10 md:ml-10">
  <div
    class="font-poppins text-[18px] md:text-2xl text-[#C44055] text-center md:my-6 w-fit"
    >
    Reports
  </div>
</div>

<div>
  <mat-form-field>
    <mat-label class="">Status</mat-label>
    <mat-select [formControl]="statusCtrl">
      @for (status of ['OPEN', 'RESOLVED']; track status) {
        <mat-option [value]="status">
          {{ status }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
<div class="p-4">
  <div
    class="shadow-black bg-gray-100 border border-gray-400 rounded-lg mx-auto min-w-[300px]"
    >
    <div class="flex flex-wrap flex-row justify-between px-4"></div>
    <div class="overflow-x-scroll">
      <table
        mat-table
        [dataSource]="bugreport"
        class="mat-elevation-z8 bg-gray-100"
        >
        <ng-container matColumnDef="id">
          <div class="flex gap-2">
            <th mat-header-cell *matHeaderCellDef>
              <div class="flex gap-2 font-bold">ID <button></button></div>
            </th>
          </div>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="reportedBy">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">ReportedBy<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.reportedBy }}</td>
        </ng-container>

        <ng-container matColumnDef="questionId">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">QuestionID<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.questionId }}</td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Details<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.details }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Status<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element" class="w-14">
            <mat-select
              [(value)]="element.status"
              (selectionChange)="updateStatus(element)"
              >
              @for (status of ['OPEN', 'RESOLVED']; track status) {
                <mat-option
                  [value]="status"
                  >
                  {{ status }}
                </mat-option>
              }
            </mat-select>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Date<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.date }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Action<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="goToQuestion(element)">
              View
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
  >
</mat-paginator>
