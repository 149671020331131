import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  signal,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { derivedAsync } from 'ngxtension/derived-async';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { CategoryService } from '../category.service';
import { FilterDTO } from '../app.model';
import { map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { CategoryAddComponent } from '../category-add/category-add.component';
import { JsonPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { createNotifier } from 'ngxtension/create-notifier';
import { CategoryEditComponent } from '../category-edit/category-edit.component';
import { KeycloakService } from 'keycloak-angular';
import { getRoleFromKc } from '../Utils';

@Component({
  selector: 'app-category-list',
  standalone: true,
  imports: [RouterLink, CategoryAddComponent, JsonPipe],
  templateUrl: './category-list.component.html',
  styleUrl: './category-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryListComponent {
  readonly queryParams = injectQueryParams();
  readonly categoryService = inject(CategoryService);

  kc = inject(KeycloakService);
  readonly role = getRoleFromKc(this.kc);
  readonly p = this.queryParams();
  readonly toast = inject(ToastrService);
  readonly dialog = inject(MatDialog);
  readonly destroyRef = inject(DestroyRef);
  refreshNotifier = createNotifier();

  readonly load = signal(true);
  readonly categories = derivedAsync(() => {
    const { parentId } = this.queryParams();

    this.refreshNotifier.listen();

    const filter: FilterDTO = {
      pageNumber: 1,
      pageSize: 100,
      params: {
        parentId: parentId,
      },
    };
    return this.categoryService.filterData(filter).pipe(map((p) => p.elements));
  });

  openCategoryAddDialog(pId?: number) {
    let parentId = this.queryParams()['parentId'];
    if (pId) {
      parentId = pId;
    }

    this.dialog
      .open(CategoryAddComponent, {
        data: {
          parentId: parentId,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((success) => {
        if (success) {
          this.toast.success('added');
          this.refreshNotifier.notify();
        }
      });
  }
  openCategoryEditDialog(id: number | undefined) {
    if (!id) return;

    this.dialog
      .open(CategoryEditComponent, {
        data: {
          categoryId: id,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((success) => {
        if (success) {
          this.toast.success('edited');
          this.refreshNotifier.notify();
        }
      });
  }
  deleteCategory(id: number | undefined) {
    if (!id) return;
    this.categoryService
      .delete(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((success) => {
        if (success) {
          this.toast.success('deleted');
          this.refreshNotifier.notify();
        }
      });
  }
}
