import { Component, OnInit, computed } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { QuestionGroup } from '../../questions.model';
import { QuestionGroupService } from '../questions.service';
import { Subject, map, switchMap, tap } from 'rxjs';
import { CommonModule, JsonPipe, Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { AppService, Branch } from 'src/app/app.service';

@Component({
  selector: 'app-question-group-edit',
  templateUrl: './question-group-edit.component.html',
  styleUrls: ['./question-group-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatSelectModule,
  ],
})
export class QuestionGroupEditComponent implements OnInit {
  constructor(
    private questionService: QuestionGroupService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private location: Location
  ) {}
  questionGroup$: any;
  id: number | undefined;

  ngOnInit(): void {
    this.questionGroup$ = this.route.params.pipe(
      map((params) => params['id']),
      tap((id) => (this.id = id)),
      switchMap((id) => this.questionService.getById(id)),
      tap((question) => this.questionForm.patchValue(question as any))
    );
  }

  questionGListChanges = new Subject<void>();
  questionForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    numOfShortQuestions: new FormControl(-1),
    numOfLongQuestions: new FormControl(-1),
  });

  submit() {
    const question = this.questionForm.value;
    const questionG: QuestionGroup = {
      title: question.title ?? '',
      details: question.details ?? '',
      numOfLongQuestions: question.numOfLongQuestions ?? 0,
      numOfShortQuestions: question.numOfShortQuestions ?? 0,
    };
    this.questionService.update(this.id!, questionG).subscribe((res: any) => {
      this.toastrService.success('Edited successfully', '', { timeOut: 3000 });
      this.location.back();
    });
  }
}
