import { JsonPipe } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { RouterLinkWithHref } from '@angular/router';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { ToastrService } from 'ngx-toastr';

import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterDTO } from '../app.model';
import { SystemNotificationService } from '../notification.service';
import { SystemNotification } from '../notification/notification.model';
import { PRIORITY } from '../questions/questions.model';

@Component({
  selector: 'app-notificationadd',
  templateUrl: './notificationadd.component.html',
  styleUrls: ['./notificationadd.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    UploadFileComponent,
    FileUploadComponent,
  ],
})
export class NotificationaddComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { id: number },
    private notificationService: SystemNotificationService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NotificationaddComponent>,
    private toastService: ToastrService,
    private destroyRef: DestroyRef
  ) {
    this.notificationForm = this.fb.group({
      title: new FormControl(''),
      details: new FormControl(''),
      priority: new FormControl<string | null>(null, Validators.required),
      createdBy: new FormControl(''),
      createdTime: new FormControl(''),
      validity: new FormControl(),
      categoryId: new FormControl(null),
    });
  }
  notificationForm: FormGroup;

  id: number | undefined;
  destroy$ = new Subject<any>();
  Priority = Object.keys(PRIORITY);
  optionCtrl = new FormControl('');
  correctOptionCtrl = new FormControl<String | null>(null);
  idCtrl = new FormControl('');
  selectedFile: File | null = null;
  notificationListChanges$ = new Subject<void>();
  notifications!: Observable<SystemNotification[]>;

  ngOnInit(): void {
    this.notifications = this.notificationForm?.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 5,
        };
        return this.notificationService
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );
  }

  submit() {
    const notification = this.notificationForm.value;
    let notificationG: SystemNotification = {
      title: notification.title ?? '',
      details: notification.details ?? '',
      priority: notification.priority ?? '',
      createdBy: notification.createdBy ?? '',
      createdTime: notification.createdTime,
      validDays: notification.validity,
      categoryId: notification.categoryId ?? null,
    };

    this.notificationService.create(notificationG).subscribe((res: any) => {
      this.toastService.success('Added successfully', '', { timeOut: 3000 });
      this.dialogRef.close(true);
    });
  }
}
