import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { Question, QuestionDetail } from '../questions.model';
import { Observable, tap } from 'rxjs';
import { PageDTO, RestResponse } from 'src/app/app.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionService extends AbstractService<Question> {
  serviceName(): string {
    return 'questions';
  }
  public filterByUnit(unitId: number): Observable<PageDTO<Question>> {
    return this.http.get<PageDTO<Question>>(
      `${this.BASE_URL}/filter/${unitId}`
    );
  }

  searchQuestions(searchTerm: string): Observable<QuestionDetail[]> {
    return this.http.get<QuestionDetail[]>(
      `${this.BASE_URL}/search?term=${searchTerm}`
    );
  }
  uploadExcel(
    file: File,
    unitId: number,
    appendQs?: boolean
  ): Observable<RestResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('unit', unitId.toString());
    let url = '/bulk-import-excel';

    return this.http.post<RestResponse>(this.BASE_URL + url, formData);
  }

  copyUnit(fromUnitId: number, toUnitId: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(
      `${this.BASE_URL}/copy-unit/from/${fromUnitId}/to/${toUnitId}`
    );
  }
}
