import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Signal,
  signal,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { derivedAsync } from 'ngxtension/derived-async';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { CategoryService } from '../category.service';
import { FilterDTO } from '../app.model';
import { map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { CategoryAddComponent } from '../category-add/category-add.component';
import { JsonPipe, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { createNotifier } from 'ngxtension/create-notifier';
import { CategoryEditComponent } from '../category-edit/category-edit.component';
import { KeycloakService } from 'keycloak-angular';
import { getRoleFromKc } from '../Utils';
import { MatIcon } from '@angular/material/icon';
import { S3Service } from '../s3.service';
import { QuestionSearchComponent } from '../question-search/question-search.component';

@Component({
  selector: 'app-category-list',
  standalone: true,
  imports: [
    RouterLink,
    CategoryAddComponent,
    JsonPipe,
    MatIcon,
    NgIf,

    QuestionSearchComponent,
  ],
  templateUrl: './category-list.component.html',
  styleUrl: './category-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryListComponent {
  readonly queryParams = injectQueryParams();
  readonly categoryService = inject(CategoryService);

  kc = inject(KeycloakService);
  readonly role = getRoleFromKc(this.kc);
  readonly p = this.queryParams();
  readonly toast = inject(ToastrService);
  readonly dialog = inject(MatDialog);
  readonly destroyRef = inject(DestroyRef);
  refreshNotifier = createNotifier();

  readonly load = signal(true);
  readonly categories = derivedAsync(() => {
    const { parentId } = this.queryParams();

    this.refreshNotifier.listen();

    const filter: FilterDTO = {
      pageNumber: 1,
      pageSize: 100,
      params: {
        parentId: parentId,
      },
    };
    return this.categoryService.filterData(filter).pipe(map((p) => p.elements));
  });
  constructor(private s3Service: S3Service) {}
  readonly imgFileUpload: Signal<ElementRef> = viewChild.required('imgUpload');
  openImgFileUpload() {
    this.imgFileUpload().nativeElement.click();
  }

  convertToBlob = (file: File, cb: (blob: Blob) => void) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target == null) return;
      let blob: Blob;
      if (typeof event.target.result === 'string') {
        // Handle the case when event.target.result is a string
        blob = new Blob([event.target.result], { type: file.type });
      } else if (event.target.result instanceof ArrayBuffer) {
        // Handle the case when event.target.result is an ArrayBuffer
        blob = new Blob([event.target.result], { type: file.type });
      } else {
        // Handle the case when event.target.result is null or an unexpected type
        console.error(
          'Unexpected data type from FileReader:',
          typeof event.target.result
        );
        return;
      }
      cb(blob);
    };
    reader.readAsArrayBuffer(file);
  };
  selectedFile: File | null = null;
  // onFileUpload(event: Event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   const files = inputElement.files; // This gives you access to the selected file(s)

  //   if (files && files.length > 0) {
  //     // You can now work with the selected file(s)
  //     this.selectedFile = files[0];
  //   }

  //   // this.file = event.target.files[0];
  // }
  fileUploads(name: string) {
    // Replace spaces with %20 in the file name
    if (this.selectedFile) {
      this.s3Service.uploadFile(
        this.selectedFile,
        'Assets/faculty/' + this.selectedFile.name
      );
    }
    // this.s3Service.removeFile(name).subscribe(() => {
    //   console.log(`File ${name} deleted from S3.`);
    // });
  }
  openCategoryAddDialog(pId?: number) {
    let parentId = this.queryParams()['parentId'];
    if (pId) {
      parentId = pId;
    }

    this.dialog
      .open(CategoryAddComponent, {
        data: {
          parentId: parentId,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((success) => {
        if (success) {
          this.toast.success('added');
          this.refreshNotifier.notify();
        }
      });
  }
  selectedPdf: File | null = null;
  pdfId: number | undefined;

  onPdfSelected(event: Event, id: number) {
    const el = event.target as HTMLInputElement;
    if (el && el.files) {
      this.selectedPdf = el.files[0];
      this.pdfId = id;
    }
  }

  clearPdfSelection() {
    this.selectedPdf = null;
    this.pdfId = undefined;
  }

  uploadPdf(categoryName: string) {
    if (this.selectedPdf) {
      const space = ' ';
      const filePath = `Syllabus${space}License/${this.selectedPdf.name}`;
      this.s3Service.uploadFile(this.selectedPdf, filePath).subscribe(() => {
        console.log('PDF uploaded successfully');
      });
      this.clearPdfSelection();
    }
  }
  openCategoryEditDialog(id: number | undefined) {
    if (!id) return;

    this.dialog
      .open(CategoryEditComponent, {
        data: {
          categoryId: id,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((success) => {
        if (success) {
          this.toast.success('edited');
          this.refreshNotifier.notify();
        }
      });
  }

  file: File | null = null;
  id: number | undefined;
  readonly imgUrl: WritableSignal<null | string> = signal(null);
  onImageSelected(event: Event, id: number) {
    const el = event.target as HTMLInputElement;
    if (el && el.files) {
      const selectedFile = el.files[0];
      this.selectedFile = el.files[0];
      this.id = id;
      const fileSizeMB = selectedFile.size / (1024 * 1024); // Size in MB
      // if (fileSizeMB > 10) {
      //   this.toast.warning('Max file Size allowed is 10mb');
      //   return;
      // }

      this.convertToBlob(selectedFile, (blob) => {
        this.file = selectedFile;
        const imageUrl = URL.createObjectURL(blob);
        this.imgUrl.set(imageUrl);
      });
    }
  }
  removeImage(url: string) {
    // this.parsedImageUrls.splice(index, 1); // Remove image URL from list
    // const details = this.unitForm.get('details')?.value || '';
    // const markdownRegex = /!\[.*?\]\((.*?)\)/g;

    if (confirm('Are you sure you want to delete?')) {
      // Extract the relative file path
      const baseUrl = 'engineering_quiz/files/Assets/faculty/' + url + '.png';
      // Remove file from S3
      this.s3Service.removeFile(baseUrl).subscribe(() => {
        console.log(`File ${baseUrl} deleted from S3.`);
      });
    }
    let updatedDetails = '';
    let match: RegExpExecArray | null;
    let i = 0;
    // while ((match = markdownRegex.exec(details)) !== null) {
    //   if (i !== index) updatedDetails += `![image](${match[1]})\n`;
    //   i++;
    // }
  }
  removePdf(url: string) {
    // this.parsedImageUrls.splice(index, 1); // Remove image URL from list
    // const details = this.unitForm.get('details')?.value || '';
    // const markdownRegex = /!\[.*?\]\((.*?)\)/g;

    if (confirm('Are you sure you want to delete?')) {
      // Extract the relative file path
      const baseUrl = 'engineering_quiz/files/Syllabus+License/' + url + '.pdf';
      // Remove file from S3
      this.s3Service.removeFile(baseUrl).subscribe(() => {
        console.log(`File ${baseUrl} deleted from S3.`);
      });
    }
    let updatedDetails = '';
    let match: RegExpExecArray | null;
    let i = 0;
    // while ((match = markdownRegex.exec(details)) !== null) {
    //   if (i !== index) updatedDetails += `![image](${match[1]})\n`;
    //   i++;
    // }
  }
  deleteCategory(id: number | undefined) {
    if (!id) return;
    this.categoryService
      .delete(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((success) => {
        if (success) {
          this.toast.success('deleted');
          this.refreshNotifier.notify();
        }
      });
  }
}
