import { Component, OnInit, inject, output } from '@angular/core';
import { Observable, debounceTime, filter, map, switchMap } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { User, UserService } from '../user.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import {
  MatAutocompleteModule,
  MatOption,
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'user-search',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatAutocompleteModule,
    MatInput,
    MatOption,
    AsyncPipe,
    ReactiveFormsModule,
  ],
  templateUrl: './user-search.component.html',
  styleUrl: './user-search.component.scss',
})
export class UserSearchComponent implements OnInit {
  readonly searchCtrl = new FormControl();

  readonly userService = inject(UserService);
  users$!: Observable<User[]> | undefined;
  readonly selected = output<User>();

  ngOnInit(): void {
    this.users$ = this.searchCtrl.valueChanges.pipe(
      debounceTime(500),
      filter((q) => !!q && typeof q !== 'object'),
      switchMap((s) => {
        return this.userService
          .searchUser((s as any)!)
          .pipe(map((u) => u.elements));
      })
    );
  }

  displayFn(user: any): string {
    return user && user.email ? user.email : '';
  }
}
