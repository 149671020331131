import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import {
  ActivatedRoute,
  RouterLink,
  RouterLinkWithHref,
} from '@angular/router';
import { S3Service } from '../s3.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SystemNotificationService } from '../notification.service';
import { HttpHeaders } from '@angular/common/http';
import { FilterDTO } from '../app.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SystemNotification } from '../notification/notification.model';
import { PRIORITY } from '../questions/questions.model';
import { MatInputModule } from '@angular/material/input';
@Component({
  selector: 'app-notification-edit',
  templateUrl: './notification-edit.component.html',
  styleUrls: ['./notification-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    RouterLinkWithHref,
    ReactiveFormsModule,
    MatPaginatorModule,
    RouterLink,
    MatInputModule,
  ],
})
export class NotificationEditComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  constructor(
    private route: ActivatedRoute,

    private fb: FormBuilder,
    private s3Service: S3Service,
    private toastService: ToastrService,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
    private systemNotificationService: SystemNotificationService
  ) {
    this.notificationForm = this.fb.group({
      title: new FormControl(''),
      details: new FormControl(''),
      priority: new FormControl<string | null>(null, Validators.required),
      createdBy: new FormControl(''),
    });
  }

  notificationForm: FormGroup;

  id: number | undefined;

  Priority = Object.keys(PRIORITY);
  destroy$ = new Subject<any>();
  notificationGListChanges$ = new Subject<void>();
  notification$!: Observable<SystemNotification>;
  totalElements: number | undefined;

  ngOnInit(): void {
    this.notification$ = this.route.params.pipe(
      map((params) => params['id']),
      tap((id) => (this.id = id)),
      switchMap((id) => this.systemNotificationService.getById(id)),
      tap((notification) => this.notificationForm.patchValue(notification))
    );
  }

  goBack() {
    history.back();
  }
  submit() {
    const notification = this.notificationForm.value;
    const notificationValue = this.notificationForm.getRawValue();
    let notificationG: SystemNotification = {
      title: notification.title ?? '',
      details: notification.details ?? '',
      priority: notification.priority ?? '',
      createdBy: notification.createdBy,
    };

    this.systemNotificationService
      .update(this.id!, notificationG)
      .subscribe((res: any) => {
        this.toastService.success('Edited Sucessfully', '', { timeOut: 3000 });

        history.back();
      });
  }
}
