import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';

export interface User {
  id: string;
  email: string;
  coins: string;
}
@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractService<User> {
  override serviceName(): string {
    return 'users';
  }
}
