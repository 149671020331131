import { Component, OnInit, inject, output } from '@angular/core';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs';
import { Question, QuestionDetail } from '../questions/questions.model';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { QuestionService } from '../questions/questions/questions.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import {
  MatAutocompleteModule,
  MatOption,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'question-search',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatAutocompleteModule,
    MatOption,
    MatInputModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './question-search.component.html',
  styleUrl: './question-search.component.scss',
})
export class QuestionSearchComponent implements OnInit {
  readonly questionNameCtrl = new FormControl();
  searchedQuestions!: Observable<QuestionDetail[]>;
  private questionService = inject(QuestionService);
  readonly selectedQuestion = output<QuestionDetail>();

  ngOnInit(): void {
    this.searchedQuestions = this.questionNameCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      filter((q) => !!q && typeof q !== 'object'),
      switchMap((q) => this.questionService.searchQuestions(q!))
    );
  }

  displayFn(q: Question) {
    return q.title;
  }
}
