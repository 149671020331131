<h1>Discussions</h1>
<div class="flex flex-wrap flex-row justify-between px-4">
  <div class="flex flex-wrap items-center sm:gap-14 gap-3 my-auto mx-3">
    <form class="example-form mt-6">
      <mat-form-field class="example-full-width">
        <mat-label>Faculty</mat-label>
        <mat-select [formControl]="facultyCtrl">
          @for (faculty of getFaculties(); track faculty) {
          <mat-option [value]="faculty">
            {{ faculty }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div class="flex p-2">
    <mat-form-field>
      <mat-label>Category</mat-label>
      <mat-select [formControl]="categoryCtrl">
        @for (categorys of categories; track categorys.name) {
        <mat-option [value]="categorys.name">
          {{ categorys.name }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="flex flex-col gap-2 border-solid border-gray-200">
  @for(f of messages()?.elements;track f.id){

  <div
    class="flex place-items-center p-2 border-2 border-solid border-gray-200"
  >
    <div class="grow flex flex-col">
      @if(f.img?.key){

      <div>
        <img
          src="{{ f.img?.key ?? '' | appendS3 : false }}"
          class="object-cover h-[150px] w-[150px]"
        />
      </div>
      }
      <div class="flex flex-col">
        <span class="text-[10px] text-gray-500">{{ f.id }}</span>
        <span class="text-xl">{{ f.message }} </span>
        <span class="text-[10px] text-gray-500">{{ f.email }}</span>
      </div>
    </div>
    <div>
      <button
        color="warn"
        mat-icon-button
        aria-label="Example icon button with a delete icon"
        (click)="deleteMessage(f)"
      >
        <mat-icon class="text-red-700">delete</mat-icon>
      </button>
    </div>
  </div>
  }
</div>
<mat-paginator
  [length]="totalElements"
  [pageSize]="pageSize"
  [pageIndex]="pageNumber"
  (page)="handlePageEvent($event)"
  aria-label="Select page"
>
</mat-paginator>
