<div class="text-lg my-2 text-gray-700">
  <a class="text-xl font-semibold" [routerLink]="['/home/category']">Home ></a>
  <a
    class="text-xl font-semibold"
    [routerLink]="['/home/sub-Chapter', chapterId]"
    [queryParams]="{ chapter: chapter }"
    >{{ chapter }}></a
  >
  <a
    class="text-xl font-semibold"
    [routerLink]="['/home/units', subChapterId]"
    [queryParams]="{ subChapter: subChapter, chapter: chapter }"
    >{{ subChapter }} >
  </a>
  <a
    class="text-xl font-semibold"
    [routerLink]="['/home/question-sets', unitId]"
    [queryParams]="{ subChapter: subChapter, chapter: chapter, unit: unit }"
    >{{ unit }} >
  </a>
  <span class="text-xl font-medium">{{ set }} </span>
</div>
<form class="example-form">
  <mat-form-field class="w-full">
    <mat-label>Search & Add Questions</mat-label>
    <input
      matInput
      #matInput
      aria-label="State"
      [matAutocomplete]="auto"
      [formControl]="questionNameCtrl"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="addQuestionToSet($event)"
    >
      @if (searchedQuestions | async; as questions) { @for (q of questions;
      track q.id) {
      <mat-option [value]="q" (click)="matInput.value = ''">
        <div class="flex flex-wrap gap-2 text-sm text-gray-600">
          <span><span class="font-medium">Id: </span>{{ q.id }} </span>
          <span
            ><span class="font-medium">Category: </span>{{ q.category }}
          </span>
          <span
            ><span class="font-medium">Chapter: </span>{{ q.chapter }}
          </span>
          <span
            ><span class="font-medium">SubChapter: </span>{{ q.subChapter }}
          </span>
          <span><span class="font-medium">Unit: </span>{{ q.unit }} </span>
          <span><span class="font-medium">Set: </span>{{ q.set }} </span>
        </div>
        <div class="mb-2 text-lg font-medium">
          <span>{{ q.question }}</span>
        </div>
      </mat-option>
      }}
    </mat-autocomplete>
  </mat-form-field>

  <br />
</form>

@if (dataSource | async; as questions) { @for (q of questions; track q.id) {
<div
  class="flex place-items-center justify-between px-3 py-1 border-solid border-b-[1px] border-[#eee]"
>
  <div class="p-2 flex flex-col gap-1">
    <span class="text-xl font-normal"
      ><span>{{ q.id }}.</span> {{ q.title }}</span
    >

    <div class="flex gap-1">
      @for (option of q.options; track option) {
      <div class="group">
        <div
          class="rounded-2xl p-2 mb-4 mt-4"
          [ngClass]="
            option.correct
              ? 'bg-green-600 text-white'
              : 'bg-gray-400 text-black'
          "
        >
          {{ option.name }}
          <span
            class="text-xl group-hover:opacity-100 opacity-0 transition-opacity duration-300 ease-in-out"
          >
            <mat-icon
              (click)="openOptionEditDialog(editOptionDialog, option)"
              class="cursor-pointer"
              >edit</mat-icon
            >
          </span>
        </div>
      </div>
      }
    </div>
  </div>
  <a routerLink="/home/questions-edit/{{ q.id }}">
    <mat-icon>edit</mat-icon>
  </a>
  <div>
    <mat-icon (click)="delete(q.id)" color="warn">delete</mat-icon>
  </div>
</div>
} }

<ng-template #editOptionDialog let-data>
  <div class="flex mx-auto text-xl md:text-2xl text-[#C44055] text-center">
    Edit Option
  </div>

  <form
    class="flex flex-col p-10 sm:flex-row [&div>input]:bg-[#E8F9F0] bg-[#295E84] rounded-xl gap-5"
  >
    <div
      class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
    >
      <div class="flex flex-wrap"></div>
      <mat-form-field class="mt-8">
        <mat-label>Title</mat-label>
        <input
          #input
          matInput
          [(ngModel)]="data.name"
          required
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
      <div class="flex flex-wrap mx-auto gap-3">
        <button
          (click)="editOption(data)"
          mat-raised-button
          [disabled]="data.name.length === 0"
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Save
        </button>
        <a>
          <button
            mat-raised-button
            mat-dialog-close
            color="primary"
            (click)="cancelOptionDialog()"
            class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
          >
            Cancel
          </button>
        </a>
      </div>
    </div>
  </form>
</ng-template>
