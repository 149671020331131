import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
@Pipe({
  name: 'utcToLocal',
  standalone: true,
})
export class UtcToLocalPipe implements PipeTransform {
  transform(value: string, formatStr?: string): string | undefined {
    if (!value) return;
    //check if value ends with Z then it contains tz

    // Parse the ISO 8601 string to a JavaScript Date object (UTC)
    let utcDate = new Date(value);

    // Nepal Standard Time offset in minutes (+5:45 = 5 * 60 + 45)
    const nepalTimeOffset = 5 * 60 + 45;

    // Convert UTC time to Nepali time
    if (!value.endsWith('Z'))
      utcDate = new Date(utcDate.getTime() + nepalTimeOffset * 60 * 1000);

    // Format the date using date-fns
    return format(utcDate, formatStr ?? 'yyyy-MM-dd HH:mm:ss');
  }
}
