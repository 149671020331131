import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FilterDTO, PageDTO } from './app.model';
import { Observable } from 'rxjs';

export interface CustomCoinReward {
  quantity: number;
  coinFeature: string;
  transactionType: string;
  userId: string;
  message: string;
}
export interface CoinTransaction {
  id: number;
  transactionType: string;
  transactionDate: string;
  amount: number;
  coinFeature: string;
  email: string;
}
@Injectable({
  providedIn: 'root',
})
export class CoinTransactionService {
  constructor(private http: HttpClient) {}

  public filterData(
    filter: FilterDTO,
    headers?: HttpHeaders
  ): Observable<PageDTO<CoinTransaction>> {
    filter = {
      ...filter,
      search: filter.search?.replaceAll(/(?<!AND|OR)\s(?!AND|OR)/g, '%20'),
    };
    return this.http.post<PageDTO<CoinTransaction>>(
      `${environment.apiUrl}coin-history/filter`,
      filter,
      {
        headers: headers,
      }
    );
  }

  public buyCoinByAdmin(cr: any) {
    return this.http.post(`${environment.apiUrl}coin-history/create/admin`, cr);
  }
}
