import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Question } from './questions/questions.model';

export interface VectorLoadRequest {
  batchSize: number;
  model: string;
}
export interface SimilaritySearchRequest {
  search: string;
  limit: number;
}
@Injectable({
  providedIn: 'root',
})
export class VectorStoreService {
  http = inject(HttpClient);

  loadStore(req: VectorLoadRequest) {
    return this.http.post(`${environment.apiUrl}vector-store/load`, req);
  }

  searchSimilar(req: SimilaritySearchRequest) {
    return this.http.post<Question[]>(
      `${environment.apiUrl}vector-store/search`,
      req
    );
  }
}
