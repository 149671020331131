import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
@Pipe({
  name: 'utcToLocal',
  standalone: true,
})
export class UtcToLocalPipe implements PipeTransform {
  transform(value: string, formatStr?: string): string {
    // Parse the ISO 8601 string to a JavaScript Date object (UTC)
    const utcDate = new Date(value);

    const localTimeZoneOffset = new Date().getTimezoneOffset();

    const localDate = new Date(
      utcDate.getTime() - localTimeZoneOffset * 60 * 1000
    );

    return format(localDate, formatStr ?? 'yyyy-MM-dd HH:mm:ss');
  }
}
