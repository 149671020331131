import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { S3Object } from './questions/questions.model';

export type Forum = {
  id: number;
  message: string;
  img?: S3Object;
  firstName: string;
  lastName: string;
  email: string;
  profilePic: string;
  createdTime: string;
  userId: string;
};
@Injectable({
  providedIn: 'root',
})
export class DiscussionForumService extends AbstractService<Forum> {
  override serviceName(): string {
    return 'forums';
  }
}
