import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { Level, CATEGORY } from '../questions/questions.model';
import { UserService } from '../user.service';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
export const medium = ['VIBER', 'FACEBOOK', 'PHONE'] as const;
export type MEDIUM = (typeof medium)[number];
export interface CallHistory {
  received: Boolean;
  followUpDate: string;
  notes: string;
  medium: string; //WHATSAPP,PHONE,VIBER
  calledTo: string;
}
@Component({
  selector: 'app-call-historyadd',
  standalone: true,
  templateUrl: './call-historyadd.component.html',
  styleUrl: './call-historyadd.component.scss',
  imports: [
    MatDatepickerModule,
    MatNativeDateModule,
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
  ],
})
export class CallHistoryaddComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.callHistoryForm = this.fb.group({
      received: new FormControl(false, Validators.required), // Boolean value
      followUpDate: new FormControl(new Date(), Validators.required), // Make sure it's set as a date
      notes: new FormControl(),
      medium: new FormControl<MEDIUM>('PHONE', Validators.required),
    });
  }
  readonly queryParams = injectQueryParams();
  readonly userService = inject(UserService);
  callHistoryForm: FormGroup;
  Levels = Object.keys(Level);
  Category = Object.keys(CATEGORY);
  id: number | undefined;
  userId: string | undefined;
  medium = medium;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  optionCtrl = new FormControl('');
  correctOptionCtrl = new FormControl<String | null>(null);
  idCtrl = new FormControl('');
  selectedFile: File | null = null;
  questionGListChanges$ = new Subject<void>();

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('id') || ''; // Extract userId
      console.log('User ID:', this.userId);
    });
  }
  submit() {
    const callHistory = this.callHistoryForm.value;

    let followUpDate = callHistory.followUpDate
      ? new Date(callHistory.followUpDate).toISOString().split('T')[0] // Convert Date to ISO string and remove milliseconds
      : '';

    let callH: CallHistory = {
      received: callHistory.received,
      followUpDate: followUpDate,
      notes: callHistory.notes,
      medium: callHistory.medium, // WHATSAPP, PHONE, VIBER
      calledTo: this.userId ?? '',
    };

    this.userService.userContactAdd(callH).subscribe((res: any) => {
      this.router.navigate([`/home/callHistory/${this.userId}`]);
      this.toastService.success('Added successfully', '', { timeOut: 3000 });

      //upload file here
      // }
    });
  }

  myFilter = (d: Date | null): boolean => {
    const today = new Date();
    const day = (d || today).getDay();
    today.setHours(0, 0, 0, 0); // Reset time to midnight
    if ((d ?? today) <= today) return false;
    if (day == 6) return false;
    return true;
  };

  close() {
    this.router.navigate([`/home/callHistory/${this.userId}`]);
  }
}
