
<div class="flex m-4 justify-between">
    <mat-label class="text-[20px]">Call History</mat-label>
    <button [routerLink]="['/home/callHistory-add',userId]"
          class="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600 transition duration-200"
        >
          Add Call History
        </button>

  </div>
  <table mat-table [dataSource]="(users$ | async) ?? []" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Sn </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <span class="w-[50px] line-clamp-1" [title]="element.id">
          {{ i + 1 }}
        </span>
      </td>
    </ng-container>


  <ng-container matColumnDef="calledDate">
    <th mat-header-cell *matHeaderCellDef>Last Called Date</th>
    <td mat-cell *matCellDef="let element">
      <span class="w-[50px] " [title]="element.lastCalledDate">
        {{element.calledDate | utcToLocal}}
      </span>

    </td>
  </ng-container>

  <ng-container matColumnDef="calledBy">
    <th mat-header-cell *matHeaderCellDef>Called By</th>
    <td mat-cell *matCellDef="let element">

      {{element.calledBy}}
  </td>
  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef>Notes</th>
    <td mat-cell *matCellDef="let element">

      {{element.notes}}
  </td> <ng-container matColumnDef="medium">
    <th mat-header-cell *matHeaderCellDef>Medium</th>
    <td mat-cell *matCellDef="let element">

      {{element.medium}}
  </td>
   <ng-container matColumnDef="received">
    <th mat-header-cell *matHeaderCellDef>received</th>
    <td mat-cell *matCellDef="let element">
    {{element.received ? 'Yes': 'No'}}
  </td>
  </ng-container>
  <ng-container matColumnDef="followUpDate">
    <th mat-header-cell *matHeaderCellDef>Follow Up Date</th>
    <td mat-cell *matCellDef="let element">

      {{element.followUpDate}}
  </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
>
</mat-paginator>
