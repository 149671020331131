<form
  [formGroup]="coinForm"
  (ngSubmit)="submit()"
  class="flex flex-col gap-2 max-w-xs mx-auto"
>
  <mat-form-field class="example-full-width">
    <mat-label>Amount of Coin</mat-label>
    <input formControlName="quantity" required matInput type="number" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Coin Feature</mat-label>
    <mat-select formControlName="coinFeature" required>
      @for (cf of coinFeatures; track cf) {
      <mat-option [value]="cf">{{ cf }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Transaction Type</mat-label>
    <mat-select formControlName="transactionType" required>
      @for (cf of ['BUY', 'SPEND']; track cf) {
      <mat-option [value]="cf">{{ cf }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-checkbox formControlName="all" [checked]="false">
    Send to All
  </mat-checkbox>
  <mat-form-field class="example-full-width">
    <mat-label>User</mat-label>
    <input
      type="text"
      placeholder="Enter user email"
      matInput
      formControlName="user"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (user of users$ | async; track user.id) {
      <mat-option [value]="user">{{ user.email }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <mat-label>Leave a Message</mat-label>
    <textarea
      formControlName="message"
      matInput
      placeholder="Ex. Rewarded for your ..."
    ></textarea>
  </mat-form-field>

  <button [disabled]="coinForm.invalid" mat-raised-button>Submit</button>
</form>
