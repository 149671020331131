@if(role === 'ADMIN'){
<a (click)="openCategoryAddDialog()" class="m-2">
  <button
    class="bg-[#04D9FF] text-white hover:bg-gray-300 focus:outline-none focus:bg-indigo-600 font-poppins text-[18px] md:text-xl px-5 py-2 rounded-lg"
  >
    Add Category
  </button>
</a>
}

<div class="grid grid-cols-2 w-fit gap-2 mx-auto">
  @for(c of categories();track c.id){
  <div
    class="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow-md break-all"
  >
    <h2 class="mb-2 font-normal text-gray-900">{{ c.name }}</h2>
    @if(c.details){
    <p class="text-gray-700 text-sm">{{ c.details | json }}</p>
    }

    <div class="mt-4 flex flex-col justify-between">
      <span class="text-gray-600">ID: {{ c.id }}</span>
      <div class="flex flex-wrap gap-1">
        @if(c.leaf && role === 'ADMIN'){
        <button
          (click)="openCategoryAddDialog(c.id)"
          class="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600 transition duration-200"
        >
          Add Category Inside
        </button>
        }
        <a
          class="bg-green-500 text-white px-4 py-2 mx-2 rounded hover:bg-green-600 transition duration-200"
          [routerLink]="c.leaf ? ['/home/chapters', c.id] : ['/home/category']"
          [queryParams]="
            !c.leaf
              ? {
                  parentId: c.id
                }
              : {}
          "
        >
          <button>{{ c.leaf ? "View Chapters" : "Open" }}</button>
        </a>
        @if(role === 'ADMIN'){
        <button
          (click)="openCategoryEditDialog(c.id)"
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
        >
          Edit
        </button>
        <button
          (click)="deleteCategory(c.id)"
          class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200 ml-2"
        >
          Delete
        </button>
        }
      </div>
    </div>
  </div>
  }
</div>
