import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { JsonPipe } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLinkWithHref } from '@angular/router';
import {
  Observable,
  Subject,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FilterDTO } from 'src/app/app.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Options, Question } from '../questions/questions.model';
import { QuestionService } from '../questions/questions/questions.service';
import { OptionsService } from './options.service';
import { QuestionGroupAddComponent } from '../questions/Questions-Group/question-group-add/question-group-add.component';
import { QuestionAddComponent } from '../questions/questions/questions-add/questions-add.component';
import { OptionsAddComponent } from './option-add/option-add.component';
import { OptionsEditComponent } from './option-edit/option-edit.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-questions',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule
],
})
export class OptionsComponent implements AfterViewInit {
  destoryRef = inject(DestroyRef);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  displayedColumns: string[] = ['id', 'name', 'action'];
  totalElements: number | undefined;
  dataSource!: MatTableDataSource<Options>;
  questionGroupNameCtrl = new FormControl<string>('');
  questionGrp$: Observable<Options[]> | undefined;
  questionGListChanges$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private optionService: OptionsService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}
  ngAfterViewInit(): void {
    merge(
      this.questionGListChanges$,
      this.questionGroupNameCtrl.valueChanges,
      this.paginator.page
    )
      .pipe(
        startWith({}),
        switchMap(() => {
          let search = '';
          if (this.questionGroupNameCtrl.value)
            search = `name:${this.questionGroupNameCtrl.value}*`;

          const filterDTO: FilterDTO = {
            pageNumber: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize,
            search: search,
          };
          return this.optionService.filterData(filterDTO).pipe(
            tap((page) => (this.totalElements = page.totalElements)),
            map((page) => page.elements)
          );
        })
      )
      .subscribe((optionList) => {
        this.dataSource = new MatTableDataSource(optionList);
        this.questionGListChanges$, this.dataSource._updateChangeSubscription();
        this.cdr.detectChanges();
      });
  }

  deleteGroup(id: number) {
    const delBtn = confirm(' Do you want to delete ?');
    if (delBtn == true) {
      this.optionService
        .delete(id)
        .pipe(takeUntilDestroyed(this.destoryRef))
        .subscribe(() => {
          this.toastr.success('Option deleted Successfully', '', {
            timeOut: 3000,
          });
          this.questionGListChanges$.next();
        });
    }
  }

  openOption() {
    this.dialog
      .open(OptionsAddComponent, {
        width: '500px',
      })
      .afterClosed()
      .subscribe((result) => {
        this.questionGListChanges$.next();
      });
  }
}
