import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FilterDTO, PageDTO } from './app.model';
import { Question } from './questions/questions.model';

export interface QuestionSetQuestionId {
  questionSet: number;
  question: number;
}
@Injectable({
  providedIn: 'root',
})
export class QuestionSetQuestionService extends AbstractService<QuestionSetQuestionId> {
  override serviceName(): string {
    return 'question-set-questions';
  }

  public filter(
    filter: FilterDTO,
    headers?: HttpHeaders | undefined
  ): Observable<PageDTO<Question>> {
    filter = {
      ...filter,
      search: filter.search?.replaceAll(/(?<!AND|OR)\s(?!AND|OR)/g, '%20'),
    };
    return this.http.post<PageDTO<Question>>(
      `${this.BASE_URL}/filter`,
      filter,
      {
        headers: headers,
      }
    );
  }

  public del(e: QuestionSetQuestionId): Observable<QuestionSetQuestionId> {
    const { question, questionSet } = e;
    return this.http.delete<QuestionSetQuestionId>(
      `${this.BASE_URL}/${questionSet},${question}`
    );
  }
}
