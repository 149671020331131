<div
  class="font-poppins flex mx-auto text-2xl text-[#C44055] text-center my-6 w-fit overflow-y-scroll"
>
  Add Notification
</div>
<form
  [formGroup]="notificationForm"
  class="flex flex-col p-10 md:m-8 bg-[#295E84] rounded-xl gap-5"
>
  <div
    class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
  >
    <div class="flex flex-wrap"></div>
    <mat-form-field>
      <mat-label>Title</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        placeholder="Enter Title"
        formControlName="title"
        required
      ></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Details</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        placeholder="Enter Details"
        formControlName="details"
        required
      ></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Priority</mat-label>
      <mat-select formControlName="priority">
        @for (priority of Priority; track priority) {
        <mat-option [value]="priority">
          {{ priority }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>CreatedBY</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        placeholder="Enter CreatedBY"
        formControlName="createdBy"
        required
      ></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Valid Days</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        placeholder="Enter validity"
        formControlName="validity"
        required
      ></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>CategoryId</mat-label>
      <input
        type="number"
        matInput
        placeholder="Enter category id(in number) or leave empty"
        formControlName="categoryId"
      />
    </mat-form-field>
    <div>
      <span
        >Leave category Id empty if you want to send notification to all</span
      >
    </div>

    <div class="flex flex-wrap mx-auto gap-3">
      <button
        type="button"
        [disabled]="notificationForm.invalid"
        (click)="submit()"
        mat-raised-button
        color="primary"
        class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
      >
        Save
      </button>
      <a>
        <button
          type="button"
          mat-raised-button
          mat-dialog-close
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Cancel
        </button>
      </a>
    </div>
  </div>
</form>
