import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { User } from '../app.model';
import { Options } from '../questions/questions.model';

@Injectable({
  providedIn: 'root',
})
export class OptionsService extends AbstractService<Options> {
  serviceName(): string {
    return 'options';
  }
}
