import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { Category } from './category.service';
import { Observable } from 'rxjs';
import { CallHistory } from './call-historyadd/call-historyadd.component';
import { PageDTO } from './app.model';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  coins: number;
  category: Category;
  subscriptionLevel: string;
  phone: string;
}
@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractService<User> {
  override serviceName(): string {
    return 'users';
  }

  searchUser(query: string): Observable<PageDTO<User>> {
    return this.http.get<PageDTO<User>>(`${this.API_URL}users/search/${query}`);
  }
  updateUserCall(
    id: string,
    callAttempted: boolean,
    called: boolean,
    notes: string
  ) {
    console.log('Hello ', this.API_URL);

    this.http
      .put(`${this.BASE_URL}/${id}/phone`, {
        callAttempted: callAttempted,
        called: called,
        calledNotes: notes,
      })
      .subscribe();
  }
  userContactHistory(id: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.API_URL}user-contact-history/${id}`);
  }

  userContactAdd(callH: CallHistory): Observable<CallHistory> {
    return this.http.post<CallHistory>(
      `${this.API_URL}user-contact-history`,
      callH
    );
  }
}
